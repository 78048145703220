import { fetchRetry, bugSnagNotify } from "../Utils/utility";
//for IP
export const getHospitalIdAndClinicForIp = (doctorId) => {
  const url = `${process.env.REACT_APP_CX_SERVER_ROOT}/qkams/get/doctor/profile?docId=${doctorId}`;
  return fetchRetry(url).catch((error) => {
    bugSnagNotify("All hospital and clinic for ip", doctorId, error);
  });
};

export const getAllDoctorsForIp = (siteCode) => {
  const url = `${process.env.REACT_APP_CX_SERVER2_ROOT}/qkams/doctors?sitecode=${siteCode}`;
  return fetchRetry(url).catch((error) => {
    bugSnagNotify("Fetch all doctors with sitecode failed", siteCode, error);
  });
};

export const FetchMpidWithPhoneNumber = (mobileNumber) => {
  const url = `${process.env.REACT_APP_USER_DB}/mpid/v2/${mobileNumber}`;
  return fetchRetry(url).catch((error) => {
    bugSnagNotify("Fetch mpid with number failed", mobileNumber, error);
  });
};

export const SubmitIpApi = (req_body) => {
  const url = `${process.env.REACT_APP_USER_DB}/videoconsultation/ipvideocreate`;

  // console.log("here", req_body);
  const fetchOpts = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(req_body),
  };
  return fetchRetry(url, fetchOpts).catch((error) => {
    bugSnagNotify("IP VC Submit error", fetchOpts, error);
  });
};
//End

export const sendOTPAPI = (mobileNumber) => {
  const url = `${process.env.REACT_APP_CX_SERVER2_ROOT}/api/send-otp`;
  const req_body = { mobile: mobileNumber };
  const fetchOpts = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(req_body),
  };
  return fetchRetry(url, fetchOpts).catch((error) => {
    bugSnagNotify("Send OTP failed", fetchOpts, error);
  });
};

export const verifyOTPAPI = (mobileNumber, otp) => {
  const url = `${process.env.REACT_APP_CX_SERVER2_ROOT}/api/verify-otp`;
  const req_body = {
    type: "digiDoc",
    mobile: mobileNumber,
    otp: otp,
  };
  const fetchOpts = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(req_body),
  };
  return fetchRetry(url, fetchOpts).catch((error) => {
    bugSnagNotify("verify OTP failed", fetchOpts, error);
  });
};

export const fetchAllVideoConsultations = (doctorId) => {
  const url = `${process.env.REACT_APP_USER_DB}/videoconsultation/doctor/${doctorId}`;
  return fetchRetry(url).catch((error) => {
    bugSnagNotify("Fetch All Video Consultation", doctorId, error);
  });
};

export const searchBar = (phone) => {
  const url = `${process.env.REACT_APP_CX_SERVER2_ROOT}/qkams/get/history?patient_phone=${phone}&source=ams`;
  return fetchRetry(url).catch((error) => {
    bugSnagNotify("Search failed", phone, error);
  });
};

export const fetchVideoConsultationHistory = (doctorId) => {
  const url = `${process.env.REACT_APP_USER_DB}/videoconsultation/support/${doctorId}`;
  return fetchRetry(url).catch((error) => {
    bugSnagNotify("Fetch video consultation history failed", doctorId, error);
  });
};

export const fetchTemplates = (doctorId) => {
  const url = `${process.env.REACT_APP_USER_DB}/videoconsultation/templates/${doctorId}`;

  return fetchRetry(url).catch((error) => {
    bugSnagNotify("Fetch templated failed", doctorId, error);
  });
};

export const savePrescriptionMessage = (appointmentId, message) => {
  const url = `${process.env.REACT_APP_USER_DB}/videoconsultation/prescription/${appointmentId}`;
  const req_body = {
    message: message,
  };
  const fetchOpts = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(req_body),
  };
  return fetchRetry(url, fetchOpts).catch((error) => {
    bugSnagNotify("Save prescription message failed", fetchOpts, error);
  });
};

export const getDocDetails = (doctorId) => {
  const url = `${process.env.REACT_APP_CX_SERVER2_ROOT}/qkams/get/doctor/profile/?docId=${doctorId}`;
  return fetchRetry(url).catch((error) => {
    bugSnagNotify("Get Doctor details failed", doctorId, error);
  });
};

export const getLabScanDetails = (sitecode) => {
  const url = `${process.env.REACT_APP_USER_DB}/videoconsultation/labscan/${sitecode}`;
  return fetchRetry(url).catch((error) => {
    bugSnagNotify("Fetch templated failed", sitecode, error);
  });
};

export const postLabScanToTemplates = (
  sitecode,
  type,
  data,
  doctoramsid,
  templateid
) => {
  const url = `${process.env.REACT_APP_USER_DB}/videoconsultation/template/labscan/`;
  const bodyObj = {
    data,
    sitecode,
    type,
    doctoramsid,
    templateid,
  };
  // console.log("postLabScanToTemplates", bodyObj);
  const fetchOpts = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(bodyObj),
  };
  return fetch(url, fetchOpts)
    .then((res) => res.json())
    .catch((error) => {
      bugSnagNotify("Create Lab scan to templates", fetchOpts, error);
    });
};

/**
 *
 * @param {string} doctorId
 * @returns Promise
 */

/**cx-server API */
export const fetchAbbreviation = (doctorId) => {
  const url = `${process.env.REACT_APP_CX_SERVER2_ROOT}/qkams/update/doctor`;

  const bodyObj = { docId: doctorId };

  /** Update to GET later */
  const fetchOpts = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "api-key": "56489a9bfdf45j495o95n4503jn",
      "access-token": "MGDGGDFREFCVHFDGHHF9",
    },
    body: JSON.stringify(bodyObj),
  };

  return fetchRetry(url, fetchOpts).catch((error) => {
    bugSnagNotify("Create Lab scan to templates", fetchOpts, error);
  });
};

/** userdb API */
// export const fetchAbbreviation = (doctorId) => {
//   const url = `${process.env.REACT_APP_USER_DB}/doctor/${doctorId}/abbreviation`;
//   return fetchRetry(url).catch((error) => {});
// };

/**
 *
 * @param {string} doctorId Id for logged in doctor
 * @param {sting} abvMsg Notes abbrevaition message
 * @returns Promise
 */

/** cx-server API */
/** API breaks on escape characters */
export const postAbbreviation = (doctorId, abvMsg) => {
  const url = `${process.env.REACT_APP_CX_SERVER2_ROOT}/qkams/update/doctor`;

  const bodyObj = { docId: doctorId, notesAbbrevations: abvMsg };

  const fetchOpts = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "api-key": "56489a9bfdf45j495o95n4503jn",
      "access-token": "MGDGGDFREFCVHFDGHHF9",
    },
    body: JSON.stringify(bodyObj),
  };
  return fetchRetry(url, fetchOpts).catch((error) => {
    bugSnagNotify("Post abbreviation failed", fetchOpts, error);
  });
};

/** cxuserdb API */
// export const postAbbreviation = (doctorId, abvMsg) => {
//   const url = `${process.env.REACT_APP_USER_DB}/doctor/${doctorId}/abbreviation`;
//   const bodyObj = { data: abvMsg };

//   const fetchOpts = {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify(bodyObj),
//   };
//   return fetchRetry(url, fetchOpts).catch((error) => {});
// };

/**
 *
 * @param {string} doctorId
 * @returns Promise
 */

/** cx-server API */
export const fetchDoctorSettings = (doctorId) => {
  const url = `${process.env.REACT_APP_CX_SERVER2_ROOT}/qkams/update/doctor`;

  const bodyObj = { docId: doctorId };

  /** Update to GET later */
  const fetchOpts = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "api-key": "56489a9bfdf45j495o95n4503jn",
      "access-token": "MGDGGDFREFCVHFDGHHF9",
    },
    body: JSON.stringify(bodyObj),
  };

  return fetchRetry(url, fetchOpts).catch((error) => {
    bugSnagNotify("Fetch Doctor Settings", fetchOpts, error);
  });
};
/** userdb API */
// export const fetchDoctorSettings = (doctorId) => {
//   const url = `${process.env.REACT_APP_USER_DB}/doctor/${doctorId}/settings`;
//   return fetchRetry(url).catch((error) => {});
// };

/**
 *
 * @param {string} doctorId
 * @param {object} settings
 * @returns Promise
 */

/** cx-server API */
export const updateDoctorSettings = (doctorId, settings) => {
  const url = `${process.env.REACT_APP_CX_SERVER2_ROOT}/qkams/update/doctor`;

  // console.log(settings);
  const bodyObj = { docId: doctorId, ancCardSettings: settings };

  const fetchOpts = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "api-key": "56489a9bfdf45j495o95n4503jn",
      "access-token": "MGDGGDFREFCVHFDGHHF9",
    },
    body: JSON.stringify(bodyObj),
  };
  return fetchRetry(url, fetchOpts).catch((error) => {
    bugSnagNotify("Updation failed for doctor settings", fetchOpts, error);
  });
};

/** userdb API */
// export const updateDoctorSettings = (doctorId, notificationSettings) => {
//   const url = `${process.env.REACT_APP_USER_DB}/doctor/${doctorId}/settings`;
//   const bodyObj = {
//     data: {
//       notification: notificationSettings,
//     },
//   };

//   const fetchOpts = {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify(bodyObj),
//   };
//   return fetchRetry(url, fetchOpts).catch((error) => {});
// };
