import { useEffect } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import "./App.css";
import Main from "./components/Main/Main";
import SideNav from "./components/SideNav/SideNav";
import Login from "./pages/Login";

function App() {
  useEffect(() => {
    <Redirect from="/" to="/today-appointments"></Redirect>;
  }, []);

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/">
            <SideNav />
            <Main />
          </Route>
        </Switch>
      </Router>
      <span>Env: ${process.env.REACT_APP_ENV}</span>
    </div>
  );
}

export default App;
