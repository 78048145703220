// created by ajay verma on 30-07-2020
import React, { Component } from "react";
// import Button from "@material-ui/core/Button";
// import { Container } from "@material-ui/core";
// import Grid from "@material-ui/core/Grid";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { fetchAbbreviation, postAbbreviation } from "../Api/Api";
import { Toast, Loader } from "../components/GenericComponents";
import { CONSTANTS } from "../Constants";
import amplitude from "amplitude-js";
import Paper from '@mui/material/Paper';

class MyNote extends Component {
    constructor(props) {
        super(props);
        this.state = {
            doctorId: "",
            doctorName: "",
            doctorMobile: "",
            abbreviation: "",
            loading: true,
            shouldShowToast: false,
            toastMessage: "",
            toastSeverity: "",
        };
    }

    componentDidMount() {
        this.setState(
            {
                doctorId: localStorage.getItem("d_id"),
                doctorName: localStorage.getItem("d_name"),
                doctorMobile: localStorage.getItem("d_mobile"),
            },
            () => this.refreshThePageSilently()
        );
    }

    isAuthorized = () => {
        const doctorId = localStorage.getItem("d_id");
        const doctorName = localStorage.getItem("d_name");
        const doctorMobile = localStorage.getItem("d_mobile");
        return doctorId && doctorName && doctorMobile ? true : false;
    };

    refreshThePageSilently = () => {
        this.getAbbreviation(this.state.doctorId);
    };

    getAbbreviation = (doctorId) => {
        this.setState({ loading: true });
        fetchAbbreviation(doctorId).then((response) => {
            // console.log("fetchAbbrevation", response);
            if (response && response.status) {
                let notes =
                    (response.message && response.message.notesAbbrevations) ||
                    "";
                this.setState({ abbreviation: notes, loading: false });
            } else if (response) {
                //response failed with status 0
                this.setState({ abbreviation: "", loading: false });
                this.showToast(
                    "Configuration missing. Please contact support",
                    CONSTANTS.TOAST_SEVERITY.ERROR
                );
            } else {
                this.setState({ abbreviation: "", loading: false });
                this.showToast(
                    "Network error. Please try again",
                    CONSTANTS.TOAST_SEVERITY.ERROR
                );
            }
        });
    };

    handleOnSavePress = () => {
        const abbreviationMessage = this.state.abbreviation;
        const doctorId = this.state.doctorId;
        if (abbreviationMessage && abbreviationMessage.trim())
            this.saveAbbreviation(doctorId, abbreviationMessage);
        else
            this.showToast(
                "Please write something",
                CONSTANTS.TOAST_SEVERITY.INFO
            );
    };

    saveAbbreviation = (doctorId, abbreviationText) => {
        this.setState({ loading: true });
        amplitude.getInstance().logEvent("Abbreviation_Save_Initiated");
        postAbbreviation(doctorId, abbreviationText).then((response) => {
            if (response && response.status) {
                this.setState({ loading: false, enableEdit: false });
                this.showToast(
                    "Updated successfully",
                    CONSTANTS.TOAST_SEVERITY.SUCCESS
                );
                amplitude.getInstance().logEvent("Abbreviation_Save_Success");
            } else if (response) {
                this.setState({ loading: false });
                this.showToast(
                    "Updated Failed. Try again later or contact support",
                    CONSTANTS.TOAST_SEVERITY.ERROR
                );
                amplitude.getInstance().logEvent("Abbreviation_Save_Failed");
            } else {
                this.setState({ loading: false });
                this.showToast(
                    "Network error. Please try again",
                    CONSTANTS.TOAST_SEVERITY.ERROR
                );
            }
        });
    };

    handleOnChange = (event) => {
        this.setState({ abbreviation: event.target.value });
    };

    handleOnBackPress = () => {
        this.props.history.goBack();
    };

    handleOnLogoutPress = () => {
        const lresult = window.confirm("Are you sure you want to logout?");
        if (lresult) {
            localStorage.clear();
            this.props.history.push("/");
        }
    };

    showToast = (message, alertType = CONSTANTS.TOAST_SEVERITY.INFO) => {
        this.setState({
            shouldShowToast: true,
            toastSeverity: alertType,
            toastMessage: message,
        });
    };

    hideToast = () =>
        this.setState({ shouldShowToast: false, toastMessage: "" });

    render() {
        return (
                <div style={{padding: '1.2rem 1.7rem'}}>
                    <Paper >

                        <div style={{textAlign:'center' , padding: '3rem 0'}} >
                            <h3 style={{padding: '1rem 0'}} >Description</h3>
                                <TextareaAutosize
                                    minRows={16}
                                    value={this.state.abbreviation}
                                    style={{
                                        resize: 'none',
                                        margin: "0em",
                                        padding: '1rem',
                                        borderColor: '#DEDEDE',
                                        backgroundColor: 'white',
                                        width: '40vw',
                                        height: '15vw'
                                    }}
                                    onChange={this.handleOnChange}
                                ></TextareaAutosize>
                                <br />
                                <br />
                                <div>
                                    <button 
                                        style={{marginLeft: '1vw', cursor: 'pointer',border: '0.1rem solid #851e87',minWidth: '17vw', height: '2.1rem',borderRadius: '0.7rem' ,color: 'white' , backgroundColor: '#851e87' , fontSize: '0.9rem' , fontWeight: '1rem'}}
                                        onClick={this.handleOnSavePress}
                                    >
                                        Save
                                    </button>
                                </div>
                        </div>


                        <Loader loading={this.state.loading} />
                        <Toast
                            visible={this.state.shouldShowToast}
                            severity={this.state.toastSeverity}
                            message={this.state.toastMessage}
                            handleClose={this.hideToast}
                        />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    </Paper>
                </div>
            );
    }
}

export default MyNote;
