// This project is created by krishna gurram  on 12 April 2019
import React, { useState } from "react";
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { TextField } from "@material-ui/core";
import { sendOTPAPI, verifyOTPAPI } from "../Api/Api";
import { CONSTANTS } from "../Constants";
import { Loader, Toast } from "../components/GenericComponents";
import amplitude from "amplitude-js";
import Logo from "../images/compLogo.png";
import Paper from "@mui/material/Paper";
// import { withStyles } from "@mui/styles";

function Login() {
  const history = useHistory();

  const [mobile, setMobile] = useState("");
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState("");
  // const [loadingText, setLoadingText] = useState("");

  const [shouldShowOTPBox, setShouldShowOTPBox] = useState(false);
  const [shouldShowToast, setShouldShowToast] = useState(false);
  const [toastSeverity, setToastSeverity] = useState("");
  const [toastMessage, setToastMessage] = useState("");

  // componentDidMount() {
  //     //check if user has already logged or not
  //     if (localStorage.getItem("Login") === "success") {
  //         props.history.push("/");
  //     }
  // }

  const handleMobile = (event) => {
    setMobile(event.target.value);
  };

  const handleOTP = (event) => {
    setOtp(event.target.value);
  };

  const showToast = (message, alertType) => {
    setToastMessage(message);
    setToastSeverity(alertType);
    setShouldShowToast(true);
  };

  const handleSendOTP = () => {
    // console.log(props.history);
    const mobileNumber = mobile;
    if (!mobileNumber) return;
    if (mobileNumber && mobileNumber.length === 10) {
      setShouldShowOTPBox(true);
      showToast("Sending OTP. Please wait...", CONSTANTS.TOAST_SEVERITY.INFO);
      sendOTPAPI(mobile)
        .then((response) => {
          // console.log(response);

          if (
            response &&
            response.status &&
            response.status.response_code === 200
          ) {
            //send otp success
            showToast("OTP SENT", CONSTANTS.TOAST_SEVERITY.SUCCESS);
          } else {
            //send otp failed
            showToast("sending OTP failed", CONSTANTS.TOAST_SEVERITY.ERROR);
          }
        })
        .catch((err) => {
          //error in network
          showToast(
            "Network Error. Please try again",
            CONSTANTS.TOAST_SEVERITY.ERROR
          );
        });
    } else {
      showToast(
        "Mobile number must contain 10-digit",
        CONSTANTS.TOAST_SEVERITY.ERROR
      );
    }
  };

  const handleVerifyOTP = () => {
    const mobileNumber = mobile;
    // const otp = otp;
    // console.log(otp);
    if (mobileNumber && mobileNumber.length === 10 && otp && otp.length === 6) {
      setLoading(true);
      // setLoadingText("Verifying OTP. Please wait...");

      verifyOTPAPI(mobileNumber, otp).then((response) => {
        // console.log(response);
        setLoading(false);
        // setLoadingText("");

        if (response && response.success === 0) {
          // wrong otp entered
          showToast("Wrong OTP", CONSTANTS.TOAST_SEVERITY.ERROR);
        } else if (response && response.entity && response.entity.objectId) {
          //send otp success
          const doctorId = response.entity.objectId;
          const doctorName = response.name;
          amplitude
            .getInstance()
            .init("829c5b10e843e0edefdc4d33f04ff290", doctorName);
          localStorage.setItem("d_id", doctorId);
          localStorage.setItem("d_name", doctorName);
          localStorage.setItem("d_mobile", mobileNumber);
          showToast("LOGIN SUCCESS", CONSTANTS.TOAST_SEVERITY.SUCCESS);
          amplitude.getInstance().logEvent("Login_Success");
          history.push("/today-appointments");
          // history.push("/upcoming-appointments", {
          //     doctorId,
          //     doctorName,
          //     doctorMobile: mobile,
          // });
        } else if (response) {
          // doctor does not exist with mobile number
          showToast("User does not exist", CONSTANTS.TOAST_SEVERITY.ERROR);
        } else {
          //send otp failed
          showToast(
            "OTP verification failed. Try again",
            CONSTANTS.TOAST_SEVERITY.ERROR
          );
        }
      });
    } else {
      showToast("OTP must contain 6-digit", CONSTANTS.TOAST_SEVERITY.ERROR);
    }
  };

  const hideToast = (event, reason) => {
    if (reason === "clickaway") return;
    setShouldShowToast(false);
    setToastSeverity("");
    setToastMessage("");
  };

  return (
    <div
      style={{
        position: "fixed",
        padding: 0,
        margin: 0,
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "#f1f2f2",
      }}
    >
      <Paper
        style={{
          width: "30%",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          left: "35%",
          top: "10%",
          margin: "1rem",
          padding: "0 1rem",
          position: "absolute",
        }}
      >
        <img
          alt={"Logo"}
          src={Logo}
          style={{ marginBottom: 20, marginTop: 50, width: "12em" }}
        />
        <br />

        <TextField
          // className={classes.root}
          size="small"
          variant="outlined"
          disabled={shouldShowOTPBox}
          label="Registered Mobile"
          value={mobile}
          onChange={handleMobile}
          style={{ width: "20rem" }}
        ></TextField>
        {shouldShowOTPBox ? (
          <div>
            <div>
              <br />
              <TextField
                // className={classes.root}
                size="small"
                variant="outlined"
                label="Enter OTP"
                onChange={handleOTP}
                style={{ width: "20rem" }}
              ></TextField>
              <br />
            </div>
            <div>
              <br />
              <br />
              <Button
                variant="contained"
                color="primary"
                style={{ width: "20rem", backgroundColor: "#851e87" }}
                onClick={handleVerifyOTP}
              >
                VERIFY OTP
              </Button>
            </div>
            <div>
              <br />
              <Button
                variant="outlined"
                color="secondary"
                style={{
                  width: "20rem",
                  border: "2px solid #851e87",
                  fontWeight: "bold",
                  color: "#851e87",
                }}
                onClick={handleSendOTP}
              >
                Resend OTP
              </Button>
              <br />
              <br />
              <br />
              <br />
            </div>
          </div>
        ) : (
          <div>
            <br />
            <br />
            <Button
              variant="contained"
              color="primary"
              style={{ width: "20rem", backgroundColor: "#851e87" }}
              onClick={handleSendOTP}
            >
              SEND OTP
            </Button>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
        )}
        <Loader loading={loading} />
        <Toast
          visible={shouldShowToast}
          severity={toastSeverity}
          message={toastMessage}
          handleClose={hideToast}
        />
      </Paper>
    </div>
  );
}

export default Login;
