import { CONSTANTS } from "../Constants";
import bugsnag from "@bugsnag/js";

export const fetchRetry = (url, fetchOptions, maxRetries) => {
  const MAX_RETRY = maxRetries ? maxRetries : CONSTANTS.MAX_RETRY;
  return fetch(url, fetchOptions)
    .then((response) => response.json())
    .catch((error) => {
      if (MAX_RETRY <= 1) throw error;
      return fetchRetry(url, fetchOptions, MAX_RETRY - 1);
    });
};

export const showWindowAlert = (message) => {
  window.alert(message);
};

const bugSnagClient = bugsnag.start({
  appVersion: "0.0.1",
  apiKey: "f582003be8b75e86abb9905d3df1e54a",
  // autoNotify: false,
  // notifyReleaseStages: ["production"]
});

//   /*
//       bugSnag upload source map ~>
//           bugsnag-sourcemaps upload --api-key f582003be8b75e86abb9905d3df1e54a \
//             --app-version 0.0.1 \
//             --directory
//     */

//   export const bugSnagSetUserDetails = (
//     mode,
//     apptId,
//     docId = "",
//     docName = "",
//     patientMpid = "",
//     patientName = ""
//   ) =>
//     (bugSnagClient.user = {
//       mode,
//       apptId,
//       docId,
//       docName,
//       patientMpid,
//       patientName,
//     });

export const bugSnagNotify = (errorTitle, requestBody, errorBody) => {
  // to capture meta data
  bugSnagClient.metaData = {
    request_body: requestBody,
    error_response: errorBody,
  };
  bugSnagClient.notify(new Error(errorTitle));
};
