import React from "react";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { CONSTANTS } from "../Constants";

export const Loader = (props) => {
    const { loading = false } = props;
    return (
        <Backdrop open={loading} style={{ zIndex: loading ? 1 : -1 }}>
            <CircularProgress />
        </Backdrop>
    );
};

export const Toast = (props) => {
    const {
        visible,
        severity = CONSTANTS.TOAST_SEVERITY.INFO,
        message = "",
    } = props;
    return (
        <Snackbar
            anchorOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
            style={{ marginTop: "-25px" }}
            open={visible}
            onClose={props.handleClose}
            autoHideDuration={6000}
        >
            <MuiAlert
                severity={severity}
                elevation={6}
                variant="filled"
                {...props}
            >
                {message}
            </MuiAlert>
        </Snackbar>
    );
};
