import React, { Component } from "react";
import Paper from "@mui/material/Paper";
// import Checkbox from "@mui/material/Checkbox";
// import FormGroup from "@mui/material/FormGroup";
// import FormControlLabel from "@mui/material/FormControlLabel";
import moment from "moment";
import {
  fetchAllVideoConsultations,
  savePrescriptionMessage,
  fetchVideoConsultationHistory,
  fetchDoctorSettings,
  updateDoctorSettings,
} from "../Api/Api";
import amplitude from "amplitude-js";
import { showWindowAlert } from "../Utils/utility";
import { CONSTANTS } from "../Constants";
import Divider from "@material-ui/core/Divider";
import call from "../images/Call.png";
import sms from "../images/sms.png";
import { Loader, Toast } from "../components/GenericComponents";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@mui/material";
// import InputBase from "@mui/material/InputBase";
// import Badge from "@mui/material/Badge";
// import MenuItem from "@mui/material/MenuItem";
// import Menu from "@mui/material/Menu";
// import MenuIcon from "@material-ui/icons/Menu";
// import SearchIcon from "@material-ui/icons/Search";
// import AccountCircle from "@material-ui/icons/AccountCircle";
// import MailIcon from "@material-ui/icons/Mail";
// import NotificationsIcon from "@material-ui/icons/Notifications";
// import MoreIcon from "@material-ui/icons/MoreVert";
// import { styled, alpha } from "@mui/material/styles";
import Search from "@material-ui/icons/Search";
import Clear from "@material-ui/icons/Clear";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";
import { bugSnagNotify } from "../Utils/utility";

const styles = (theme) => ({
  root: {
    //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    //     borderColor: "green"
    //   },
    //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    //     borderColor: "red"
    //   },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#99219b",
    },
    //   "& .MuiOutlinedInput-input": {
    //     color: "green"
    //   },
    //   "&:hover .MuiOutlinedInput-input": {
    //     color: "red"
    //   },
    //   "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
    //     color: "red"
    //   },
    //   "& .MuiInputLabel-outlined": {
    //     color: "green"
    //   },
    //   "&:hover .MuiInputLabel-outlined": {
    //     color: "red"
    //   },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#99219b",
    },
  },
});

class History7days extends Component {
  constructor(props) {
    super(props);
    this.state = {
      doctorId: "",
      doctorName: "",
      doctorMobile: "",
      todaysAppointments: [],
      futureAppointments: [],
      initialPageLoading: true,
      shouldShowEditPrescriptionModal: false,
      shouldShowToast: false,
      toastSeverity: "",
      toastMessage: "",
      appointmentDataForEditingPrescription: {},
      editedPrescriptionText: "",
      savePrescriptionMessageLoading: false,
      shouldShowGeneratePrescriptionButton: false,
      dSiteCode: "",
      dLifetrenzId: "",
      dClinicname: "",
      dIN: false,
      shouldShowPastHistory: false,
      pastAppointments: [],
      searchPastHistory: [],
      showSMSModalBool: false,
      selectCustomerMobile: "",
      smsMessageText: "The doctor is waiting for you to join the consultation.",
      doctorSettings: {},
      doctorNotifSettingsHM: {},
      searchValue: "",
      isSearchFilter: false,
      loading: false,
    };
    this.appointmentIdToStatusHashMap = {};
    this.setIntervalId = "";
  }

  componentDidMount() {
    this.setState(
      {
        doctorId: localStorage.getItem("d_id"),
        doctorName: localStorage.getItem("d_name"),
        doctorMobile: localStorage.getItem("d_mobile"),
      }
      // () => this.refreshThePageSilently()
    );

    const doctorName = localStorage.getItem("d_name");
    const doctorId = localStorage.getItem("d_id");
    amplitude
      .getInstance()
      .init("829c5b10e843e0edefdc4d33f04ff290", doctorName);

    // this.setIntervalId = setInterval(() => {
    //   this.refreshThePageSilently();
    // }, 30000);

    // this.fetchDoctorProfile();
    // this.getDoctorSettings(doctorId);
    this.handleOnMyPastApptHistoryPress(doctorId);
  }

  fetchDoctorProfile() {
    const url =
      "https://cx-server2.cloudninecare.com/qkams/get/doctor/profile/?docId=" +
      localStorage.getItem("d_id");

    fetch(url)
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.status === 1) {
          this.setState({
            dLifetrenzId: resp.data[0].lifetrenzId,
            dSiteCode: resp.data[0].getHospitals[0].siteCode,
            dClinicname: resp.data[0].getHospitals[0].name,
          });
        }
      })
      .catch((error) => {
        bugSnagNotify(
          "Fetch doctor profile failed",
          localStorage.getItem("d_id"),
          error
        );
        // console.log(err);
      });
  }

  getDoctorSettings = (doctorId) => {
    fetchDoctorSettings(doctorId)
      .then((response) => {
        // console.log("Doctor Settings", response);
        if (response && response.status) {
          const settings =
            (response.message && response.message.ancCardSettings) || {};

          const notificationSettings = settings.notification
            ? settings.notification
            : [];
          let notificationSettingsHM = {};
          notificationSettings.forEach(
            (notifSet) => (notificationSettingsHM[notifSet] = true)
          );
          this.setState({
            doctorSettings: settings,
            doctorNotifSettingsHM: notificationSettingsHM,
          });
        } else if (response) {
          //response failed with status 0
          this.setState({
            doctorSettings: {},
            doctorNotifSettingsHM: {},
          });
          this.showToast(
            "Configuration missing. Please contact support",
            CONSTANTS.TOAST_SEVERITY.ERROR
          );
        } else {
          this.setState({
            doctorSettings: {},
            doctorNotifSettingsHM: {},
          });
        }
      })
      .catch((error) => {
        bugSnagNotify("Fetch doctor settings failed", doctorId, error);
      });
  };

  isAuthorized = () => {
    const doctorId = localStorage.getItem("d_id");
    const doctorName = localStorage.getItem("d_name");
    const doctorMobile = localStorage.getItem("d_mobile");
    return doctorId && doctorName && doctorMobile ? true : false;
  };

  // refreshThePageSilently = () => {
  //   fetchAllVideoConsultations(this.state.doctorId).then((data) => {
  //     // console.log(data);
  //     if (data && data.length) {
  //       const sortedData = data.sort(
  //         (itema, itemb) =>
  //           moment(
  //             `${itema.date_of_appointment} ${itema.time_of_appointment}`,
  //             "YYYY-MM-DD hh:mm A"
  //           ) -
  //           moment(
  //             `${itemb.date_of_appointment} ${itemb.time_of_appointment}`,
  //             "YYYY-MM-DD hh:mm A"
  //           )
  //       );
  //       const momentToday = moment();
  //       const dateToday = momentToday.format("YYYY-MM-DD");
  //       let todaysAppointments = [];
  //       let futureAppointments = [];
  //       sortedData.forEach((val) =>
  //         val.date_of_appointment === dateToday
  //           ? todaysAppointments.push(val)
  //           : moment(val.date_of_appointment) > momentToday
  //           ? futureAppointments.push(val)
  //           : null
  //       );

  //       this.setState({
  //         todaysAppointments: todaysAppointments,
  //         futureAppointments: futureAppointments,
  //         initialPageLoading: false,
  //       });
  //       const appointmentListWithStatusChanged = sortedData.filter(
  //         (appt) =>
  //           this.appointmentIdToStatusHashMap[appt.amsappointmentid] &&
  //           this.appointmentIdToStatusHashMap[appt.amsappointmentid] ===
  //             "not_started" &&
  //           appt.status === "checkedin"
  //       );
  //       appointmentListWithStatusChanged.forEach((appt) => {
  //         const message = `${appt.patientname}, with appointment at ${appt.time_of_appointment} has joined the video consultation room and is waiting for you`;
  //         showWindowAlert(message);
  //       });
  //       const newHMap = this.createAppointmentIdToStatusHashMap(sortedData);
  //       this.appointmentIdToStatusHashMap = newHMap;
  //     } else if (data) {
  //       this.setState({
  //         initialPageLoading: false,
  //         todaysAppointments: [],
  //         futureAppointments: [],
  //       });
  //     } else this.setState({ initialPageLoading: false });
  //   });
  // };

  createAppointmentIdToStatusHashMap = (appointmentDataList) => {
    let hMap = {};
    appointmentDataList.forEach(
      (appt) => (hMap[appt.amsappointmentid] = appt.status)
    );
    return hMap;
  };

  convertAppointmentStatusToText = (status) => {
    if (status === "not_started") return "Not Yet Started";
    else if (status === "started") return "Started";
    else if (status === "checkedin") return "Patient Waiting";
    else if (status === "checkedout") return "Appointment Checkedout";
    else if (status === "cancelled") return "Appointment Cancelled";
    else return status;
  };

  initiatePhoneCall = (from, to) => {
    const url = `https://cxuserdb.cloudninecare.email/videoconsultation/callconnectv2/${from}/${to}`;

    return fetch(url)
      .then((response) => response.json())
      .catch((error) => {
        bugSnagNotify("Phone initiate failed", { from, to }, error);
      });
  };

  handleOnCallPress = (patientMobile) => {
    const docMobile = this.state.doctorMobile;
    const isConfirmed = window.confirm(
      `Are you sure to connect phone call to ${patientMobile}. You shall recieve call first and it will connect with the other person`
    );
    if (isConfirmed) {
      amplitude.getInstance().logEvent("Call_Initiated", { to: patientMobile });
      this.initiatePhoneCall(docMobile, patientMobile).then((response) => {
        // console.log(response);
        if (response && response.status)
          showWindowAlert(`Call intiated to ${patientMobile} successfully. `);
        else showWindowAlert(`Sorry! Connection with ${patientMobile} failed.`);
      });
    }
  };

  handleOnCallSupport = () => {
    const suppportNumber = ["9900025892", "7338454536"];
    const randomNumber = Math.round(Math.random());
    this.handleOnCallPress(suppportNumber[randomNumber]);
  };

  sendSMSModalfn = (bool, custmobile) =>
    this.setState(
      {
        showSMSModalBool: bool,
        selectCustomerMobile: custmobile,
        smsMessageText:
          "The doctor is waiting for you to join the consultation.",
      } //herheee
    );

  //here
  handleOnEditPresciptionPress = (appointmentData) => {
    this.setState({
      shouldShowEditPrescriptionModal: true,
      appointmentDataForEditingPrescription: appointmentData,
    });
  };

  handleOnSendPresciptionPress = (
    mpId,
    emailId,
    customerMobile,
    customerName,
    doctorName,
    prescriptionLink
  ) => {
    const message = `Hi ${customerName}, link to your prescription that you had with Dr. ${doctorName} is ${prescriptionLink}. Any issues please email to appsupport@cloudninecare.com - Cloudnine Hospitals`;
    this.showToast("Sending SMS", CONSTANTS.TOAST_SEVERITY.INFO);
    this.showToast("Sending email", CONSTANTS.TOAST_SEVERITY.INFO);
    this.initiateSMS(customerMobile, message)
      .then((response) => {
        if (response && response.status && response.status === 1) {
          this.showToast("SMS sent", CONSTANTS.TOAST_SEVERITY.SUCCESS);
          amplitude
            .getInstance()
            .logEvent("prescription_sms_sent_successfully", {
              mobile: customerMobile,
              sms: message,
              mpid: mpId,
              email: emailId,
              customerName: customerName,
              doctorName: doctorName,
              prescriptionLink: prescriptionLink,
            });
        } else {
          this.showToast("SMS failed", CONSTANTS.TOAST_SEVERITY.ERROR);
          amplitude.getInstance().logEvent("prescription_sms_sending_failed", {
            mobile: customerMobile,
            sms: message,
            mpid: mpId,
            email: emailId,
            customerName: customerName,
            doctorName: doctorName,
            prescriptionLink: prescriptionLink,
            error: response,
          });
        }
      })
      .catch((error) => {
        this.showToast("SMS failed", CONSTANTS.TOAST_SEVERITY.ERROR);
        bugSnagNotify("prescription_sms_sending_failed", customerMobile, error);
        amplitude.getInstance().logEvent("prescription_sms_sending_failed", {
          mobile: customerMobile,
          sms: message,
          mpid: mpId,
          email: emailId,
          customerName: customerName,
          doctorName: doctorName,
          prescriptionLink: prescriptionLink,
          error: error,
        });
      });

    this.initiateEmail(
      mpId,
      emailId,
      message,
      "Prescription for your video consultation"
    )
      .then((response) => {
        if (response && response.status && response.status === 1) {
          this.showToast("Email sent", CONSTANTS.TOAST_SEVERITY.SUCCESS);
          amplitude
            .getInstance()
            .logEvent("prescription_email_sent_successfully", {
              mobile: customerMobile,
              sms: message,
              mpid: mpId,
              email: emailId,
              customerName: customerName,
              doctorName: doctorName,
              prescriptionLink: prescriptionLink,
            });
        } else {
          this.showToast("Email failed", CONSTANTS.TOAST_SEVERITY.ERROR);
          amplitude
            .getInstance()
            .logEvent("prescription_email_sending_failed", {
              mobile: customerMobile,
              sms: message,
              mpid: mpId,
              email: emailId,
              customerName: customerName,
              doctorName: doctorName,
              prescriptionLink: prescriptionLink,
              error: response,
            });
        }
      })
      .catch((error) => {
        this.showToast("Email failed", CONSTANTS.TOAST_SEVERITY.ERROR);
        bugSnagNotify(
          "prescription_email_sending_failed",
          customerMobile,
          error
        );
        amplitude.getInstance().logEvent("prescription_email_sending_failed", {
          mobile: customerMobile,
          sms: message,
          mpid: mpId,
          email: emailId,
          customerName: customerName,
          doctorName: doctorName,
          prescriptionLink: prescriptionLink,
          error: error,
        });
      });
  };

  initiateSMS = (toMobile, message) => {
    const url = `https://cxuserdb.cloudninecare.email/videoconsultation/sendsms`;
    const req_body = {
      sendTo: toMobile,
      message: message,
    };
    const fetchOpts = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(req_body),
    };
    return fetch(url, fetchOpts)
      .then((response) => response.json())
      .catch((error) => {
        bugSnagNotify("SMS initiate failed", fetchOpts, error);
      });
  };

  handleGenerateAndSendPrescription = (
    mpId,
    emailId,
    customerMobile,
    customerName,
    doctorName,
    amsappointmentid,
    sitecode,
    prescriptionmessage,
    date_of_appointment,
    ltApptId
  ) => {
    const confirmation = window.confirm(
      "Are you sure? Generating new prescription will overwrite any exisitng prescription written earlier. Only saved prescription is documented"
    );
    if (confirmation) {
      this.showToast("Generating Prescription", CONSTANTS.TOAST_SEVERITY.INFO);
      // console.log(prescriptionmessage)
      if (!prescriptionmessage) {
        this.showToast(
          "No Prescription is written by doctor, so can't generate",
          CONSTANTS.TOAST_SEVERITY.ERROR
        );
      } else {
        const prescriptionWithNewLine = prescriptionmessage.replace(
          /###/g,
          "\n"
        );
        const doctorId = this.state.doctorId;
        this.initiateGeneratePres(
          mpId,
          emailId,
          customerMobile,
          customerName,
          doctorName,
          amsappointmentid,
          sitecode,
          prescriptionWithNewLine,
          date_of_appointment,
          doctorId,
          ltApptId
        ).then((response) => {
          if (response.statusValue === 1) {
            this.showToast(
              "Prescription generated successfully",
              CONSTANTS.TOAST_SEVERITY.SUCCESS
            );
            this.refreshThePageSilently();
            const prescriptionLink = response.URL;
            this.handleOnSendPresciptionPress(
              mpId,
              emailId,
              customerMobile,
              customerName,
              doctorName,
              prescriptionLink
            );
          } else
            this.showToast(
              "Prescription generation failed",
              CONSTANTS.TOAST_SEVERITY.ERROR
            );
        });
      }
    }
  };

  initiateGeneratePres = (
    mpId,
    emailId,
    customerMobile,
    customerName,
    doctorName,
    amsappointmentid,
    sitecode,
    prescriptionmessage,
    date_of_appointment,
    doctorId,
    ltApptId
  ) => {
    const url = `https://cx-server2.cloudninecare.com/addPrescription/video/consultv2`;
    const req_body = {
      appointmentId: amsappointmentid,
      mobile: customerMobile,
      textData: prescriptionmessage,
      date_of_appointment: date_of_appointment,
      siteCode: sitecode,
      mpid: mpId,
      patientname: customerName,
      doctorname: doctorName,
      amsdoctorid: doctorId,
      ltApptId: ltApptId,
    };
    const fetchOpts = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(req_body),
    };
    return fetch(url, fetchOpts)
      .then((response) => response.json())
      .catch((error) => {
        bugSnagNotify("Generate prescription failed", fetchOpts, error);
      });
  };

  initiateEmail = (mpId, emailId, message, subject) => {
    const url = `https://cxuserdb.cloudninecare.email/videoconsultation/sendemail`;
    const req_body = {
      emailid: emailId,
      mpid: mpId,
      htmlBody: message,
      subject: subject,
    };
    const fetchOpts = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(req_body),
    };
    return fetch(url, fetchOpts)
      .then((response) => response.json())
      .catch((error) => {
        bugSnagNotify("Email initiation failed", fetchOpts, error);
      });
  };

  showToast = (message, alertType) => {
    this.setState({
      shouldShowToast: true,
      toastSeverity: alertType,
      toastMessage: message,
    });
  };

  hideEditPrescriptionModal() {
    this.setState({
      shouldShowEditPrescriptionModal: false,
      appointmentDataForEditingPrescription: {},
      editedPrescriptionText: "",
      shouldShowGeneratePrescriptionButton: false,
    });
  }

  handleSavePrescription = () => {
    this.setState({ savePrescriptionMessageLoading: true });
    const appointmentId =
      this.state.appointmentDataForEditingPrescription.amsappointmentid;
    const oldPrescriptionMessage =
      this.state.appointmentDataForEditingPrescription.prescriptionmessage;
    const addendumPrescriptionMessage =
      this.state.editedPrescriptionText.trim();
    const newPrescriptionMessage =
      oldPrescriptionMessage && oldPrescriptionMessage.trim()
        ? `${oldPrescriptionMessage.trim()}\n-----Addendum-----\n${addendumPrescriptionMessage}`
        : `${addendumPrescriptionMessage}`;
    savePrescriptionMessage(appointmentId, newPrescriptionMessage).then(
      (response) => {
        // console.log(response);
        if (response && response.status) {
          this.setState({
            savePrescriptionMessageLoading: false,
            shouldShowGeneratePrescriptionButton: true,
          });
          this.showToast(
            "Prescription saved successfully",
            CONSTANTS.TOAST_SEVERITY.SUCCESS
          );
          this.refreshThePageSilently();
        } else {
          this.setState({
            savePrescriptionMessageLoading: false,
            shouldShowGeneratePrescriptionButton: false,
          });
          this.showToast(
            "Sorry. Some error occured in saving. Please try again",
            CONSTANTS.TOAST_SEVERITY.ERROR
          );
        }
      }
    );
    this.handleGeneratePrescriptionAfterSaving();
  };

  handleGeneratePresciptionPress = (
    mpId,
    emailId,
    customerMobile,
    customerName,
    doctorName,
    amsappointmentid,
    sitecode,
    prescriptionmessage,
    date_of_appointment,
    ltApptId
  ) => {
    const confirmation = window.confirm(
      "Are you sure? Generating new prescription will overwrite any exisitng prescription written earlier. Only saved prescription is documented"
    );
    if (confirmation) {
      this.showToast("Generating Prescription", CONSTANTS.TOAST_SEVERITY.INFO);
      // console.log(prescriptionmessage)
      amplitude
        .getInstance()
        .logEvent("Prescription_Dashboard_Generate_Initated");

      if (!prescriptionmessage) {
        this.showToast(
          "No Prescription is written by doctor, so can't generate",
          CONSTANTS.TOAST_SEVERITY.ERROR
        );
      } else {
        const prescriptionWithNewLine = prescriptionmessage.replace(
          /###/g,
          "\n"
        );
        const doctorId = this.state.doctorId;
        this.initiateGeneratePres(
          mpId,
          emailId,
          customerMobile,
          customerName,
          doctorName,
          amsappointmentid,
          sitecode,
          prescriptionWithNewLine,
          date_of_appointment,
          doctorId,
          ltApptId
        ).then((response) => {
          // console.log(response);
          if (response.statusValue === 1) {
            this.showToast(
              "Prescription generated successfully",
              CONSTANTS.TOAST_SEVERITY.SUCCESS
            );
            amplitude
              .getInstance()
              .logEvent("Prescription_Dashboard_Generated_Success");

            // setTimeout(function(){ window.location.reload() }, 6000);
            this.refreshThePageSilently();
          } else {
            amplitude
              .getInstance()
              .logEvent("Prescription_Dashboard_Generated_Failure");
            this.showToast(
              "Prescription generation failed",
              CONSTANTS.TOAST_SEVERITY.ERROR
            );
          }
        });
      }
    }
  };

  handleGeneratePrescriptionAfterSaving = () => {
    const appointmentData = this.state.appointmentDataForEditingPrescription;
    const doctorName = this.state.doctorName;
    const appointmentId = appointmentData.amsappointmentid;
    const siteCode = appointmentData.sitecode;
    const mpId = appointmentData.mpid;
    const patientEmail = appointmentData.patientemail;
    const patientMobile = appointmentData.mobile;
    const patientName = appointmentData.patientname;
    const appointmentDate = appointmentData.date_of_appointment;
    const oldPrescriptionMessage = appointmentData.prescriptionmessage;
    const addendumPrescriptionMessage =
      this.state.editedPrescriptionText.trim();
    const newPrescriptionMessage =
      oldPrescriptionMessage && oldPrescriptionMessage.trim()
        ? `${oldPrescriptionMessage.trim()}\n-----Addendum-----\n${addendumPrescriptionMessage}`
        : `${addendumPrescriptionMessage}`;
    const ltApptId = appointmentData.ltappointmentid;
    this.handleGeneratePresciptionPress(
      mpId,
      patientEmail,
      patientMobile,
      patientName,
      doctorName,
      appointmentId,
      siteCode,
      newPrescriptionMessage,
      appointmentDate,
      ltApptId
    );
  };

  intiateNotification = (mobile, message) => {
    // console.log(mobile, message);
    const url =
      "https://cxuserdb.cloudninecare.email/miscellaneous/send/notification/" +
      mobile +
      "/";
    const req_body = {
      title: "Message from " + this.state.doctorName,
      message: message,
    };
    const fetchOpts = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(req_body),
    };

    // console.log(url, req_body);
    return fetch(url, fetchOpts)
      .then((response) => response.json())
      .catch((error) => {
        bugSnagNotify("Notification initiation failed", fetchOpts, error);
      });
  };

  smsMessageTextValueChangeFn = (event) =>
    this.setState({ smsMessageText: event.target.value });

  handleOnChangePrescriptionText = (event) => {
    this.setState({ editedPrescriptionText: event.target.value });
  };

  sendSMSNotification = () => {
    let mobile = this.state.selectCustomerMobile;
    let smsText = this.state.smsMessageText;

    const qresult = window.confirm(
      "Are you sure send SMS & Notification to Customer?"
    );
    if (qresult) {
      this.initiateSMS(mobile, smsText)
        .then((res) => {
          if (res && res.status && res.status === 1) {
            this.showToast(
              "SMS and Notification is send to Customer",
              CONSTANTS.TOAST_SEVERITY.SUCCESS
            );
            amplitude.getInstance().logEvent("send_sms_now_success", {
              mobile: mobile,
              sms: smsText,
            });
          } else {
            this.showToast(
              "SMS and Notification was not sent due to some error",
              CONSTANTS.TOAST_SEVERITY.ERROR
            );
            amplitude.getInstance().logEvent("send_sms_now_failed", {
              mobile: mobile,
              sms: smsText,
              error: res,
            });
          }
        })
        .catch((error) => {
          this.showToast(
            "SMS and Notification was not sent due to some error",
            CONSTANTS.TOAST_SEVERITY.ERROR
          );
          bugSnagNotify("Send sms failed for", mobile, error);
          amplitude.getInstance().logEvent("send_sms_now_failed", {
            mobile: mobile,
            sms: smsText,
            error: error,
          });
        });

      this.intiateNotification(mobile, smsText)
        .then((res) => {
          if (res && res.status && res.status === 1) {
            amplitude.getInstance().logEvent("send_notification_now_success", {
              mobile: mobile,
              sms: smsText,
            });
          } else {
            amplitude.getInstance().logEvent("send_notification_now_failed", {
              mobile: mobile,
              sms: smsText,
              error: res,
            });
          }
        })
        .catch((error) => {
          amplitude.getInstance().logEvent("send_notification_now_failed", {
            mobile: mobile,
            sms: smsText,
            error: error,
          });
        });
      this.sendSMSModalfn(false, "");
    }
  };

  hideToast = (event, reason) => {
    if (reason === "clickaway") return;
    this.setState({
      shouldShowToast: false,
      toastSeverity: "",
      toastMessage: "",
    });
  };

  handleOnChangeNotifSettings = (notifSetting) => (event) => {
    // console.log(event.target,event.target.checked,notifSetting);
    const newNotifSettingsHM = {
      ...this.state.doctorNotifSettingsHM,
      [notifSetting]: event.target.checked,
    };
    this.setState({ doctorNotifSettingsHM: newNotifSettingsHM });
    this.updateDoctorNotifSettings(newNotifSettingsHM);
  };

  updateDoctorNotifSettings = (notifSettingsHM) => {
    // only enableb notification settings are filtered and updated
    const doctorId = this.state.doctorId;
    delete notifSettingsHM.null;
    const notifSettingsArr = Object.keys(notifSettingsHM).filter(
      (set) => notifSettingsHM[set]
    );

    // console.log(notifSettingsArr);
    //currently only notification settings update is supported
    this.showToast("Updating. Please wait", CONSTANTS.TOAST_SEVERITY.INFO);
    const settingsObj = {
      ...this.state.doctorSettings,
      notification: notifSettingsArr,
    };

    updateDoctorSettings(doctorId, settingsObj).then((response) => {
      // console.log(response);
      if (response && response.status) {
        this.showToast(
          "Updated successfully",
          CONSTANTS.TOAST_SEVERITY.SUCCESS
        );
        // amplitude.getInstance().logEvent('Abbreviation_Save_Success');
      } else if (response) {
        this.showToast(
          "Updated Failed. Try again later or contact support",
          CONSTANTS.TOAST_SEVERITY.ERROR
        );
        // amplitude.getInstance().logEvent('Abbreviation_Save_Failed');
      } else {
        this.showToast(
          "Network error. Please try again",
          CONSTANTS.TOAST_SEVERITY.ERROR
        );
      }
    });
  };

  getRowUIOfAppointment = (ite) => (
    <div
      style={{
        display: "flex",
        justifyContent: "space-around",
        padding: "0.5rem 2rem",
      }}
    >
      <div>
        <h4
          style={{
            width: "5rem",
            padding: "0.6rem",
            height: "4rem",
            backgroundColor: "#f3f3f3",
            fontSize: "0.9rem",
            color: "black",
            borderRadius: "0.5rem",
          }}
        >
          {moment(ite.date_of_appointment).format("DD MMM YYYY")}
        </h4>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "63rem",
          height: "9rem",
          padding: "1rem",
          border: "1px solid #D3D3D3",
          borderRadius: "0.5rem",
        }}
      >
        <div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <h4>{ite.patientname}</h4>
            <p
              style={{
                borderLeft: "0.09rem solid #D3D3D3",
                margin: "0 1.2rem",
                height: "1.1rem",
              }}
            ></p>
            {ite.status === "not_started" && (
              <button
                style={{
                  border: "0.1rem solid #f03a61",
                  fontWeight: "bold",
                  width: "7.6rem",
                  height: "1.6rem",
                  borderRadius: "0.7rem",
                  color: "#f03a61",
                  backgroundColor: "#FFE5E5",
                  fontSize: "0.75rem",
                }}
              >
                {"Not Yet Started"}
              </button>
            )}
            {(ite.status === "started" || ite.status === "checkedin") && (
              <button
                style={{
                  border: "0.1rem solid #ff9b54",
                  fontWeight: "bold",
                  width: "7.6rem",
                  height: "1.6rem",
                  borderRadius: "0.7rem",
                  color: "#ff9b54",
                  backgroundColor: "#fff5ed",
                  fontSize: "0.75rem",
                }}
              >
                {"Patient Waiting"}
              </button>
            )}
            {ite.status === "checkedout" && (
              <button
                style={{
                  border: "0.1rem solid #21d393",
                  fontWeight: "bold",
                  width: "7rem",
                  height: "1.6rem",
                  borderRadius: "0.7rem",
                  color: "#21d393",
                  backgroundColor: "#e3fff3",
                  fontSize: "0.75rem",
                }}
              >
                {"Checkedout"}
              </button>
            )}
            {ite.status === "cancelled" && (
              <button
                style={{
                  border: "0.1rem solid #ff9b54",
                  fontWeight: "bold",
                  width: "7.6rem",
                  height: "1.6rem",
                  borderRadius: "0.7rem",
                  color: "#ff9b54",
                  backgroundColor: "#fff5ed",
                  fontSize: "0.75rem",
                }}
              >
                {"Cancelled"}
              </button>
            )}
            {ite.status === "missed" && (
              <button
                style={{
                  border: "0.1rem solid #ff9b54",
                  fontWeight: "bold",
                  width: "7.6rem",
                  height: "1.6rem",
                  borderRadius: "0.7rem",
                  color: "#ff9b54",
                  backgroundColor: "#fff5ed",
                  fontSize: "0.75rem",
                }}
              >
                {"No Show"}
              </button>
            )}
          </div>

          <div
            style={{ display: "flex", alignItems: "center", padding: "1rem 0" }}
          >
            <div
              style={{
                display: "flex",
                fontSize: "0.8rem",
                marginRight: "2vw",
              }}
            >
              <div style={{ color: "#989898" }}>Mpid</div>
              <p style={{ marginLeft: "1rem" }}>{ite.mpid}</p>
            </div>

            <div
              style={{
                display: "flex",
                fontSize: "0.8rem",
                marginRight: "2vw",
              }}
            >
              <div style={{ color: "#989898" }}>Contact</div>
              <p style={{ marginLeft: "1rem" }}>{ite.mobile}</p>
            </div>

            <div style={{ display: "flex", fontSize: "0.8rem" }}>
              <div style={{ color: "#989898" }}>Time</div>
              <p style={{ marginLeft: "1rem" }}>{ite.time_of_appointment}</p>
            </div>
          </div>
          {ite.status === "checkedout" ? (
            <div style={{ display: "flex" }}>
              <a
                style={{ textDecoration: "none" }}
                href={ite.prescriptionlink}
                target="_blank"
                rel="noreferrer"
              >
                <h4
                  style={{
                    color: "#99219b",
                    fontSize: "0.8rem",
                    fontWeight: "2rem",
                    cursor: "pointer",
                  }}
                >
                  View Prescription
                </h4>
              </a>

              <h4
                style={{
                  color: "#99219b",
                  fontSize: "0.8rem",
                  fontWeight: "2rem",
                  margin: "0 1.2rem",
                  cursor: "pointer",
                }}
                onClick={() => this.handleOnEditPresciptionPress(ite)}
              >
                Edit Prescription
              </h4>
            </div>
          ) : null}
        </div>
        <div>
          <div style={{ display: "flex", padding: "0 1vw" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                margin: "0 1vw",
              }}
              onClick={() => this.handleOnCallPress(ite.mobile)}
            >
              <img
                src={call}
                style={{ height: "2.5vw", width: "2.5vw" }}
                alt="call"
              />
              <p style={{ margin: "0 0.6vw" }}>Call Now</p>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => this.sendSMSModalfn(true, ite.mobile)}
            >
              <img
                src={sms}
                style={{ height: "2.25vw", width: "2.25vw" }}
                alt="sms"
              />
              <p style={{ margin: "0 0.6vw" }}>Send SMS</p>
            </div>
          </div>
          {/* {
                            ite.status === "started" || ite.status === "checkedin" ?
                                <div style={{marginLeft: '7vw'}}>
                                    <Button
                                        style={{marginTop: '1.9rem',textTransform: 'none',cursor: 'pointer',border: '0.1rem solid #851e87',width: '12rem', height: '2.1rem',borderRadius: '0.7rem' ,color: 'white' , backgroundColor: '#851e87' , fontSize: '0.8rem'}}
                                        onClick={() =>
                                            amplitude
                                                .getInstance()
                                                .logEvent("Start_Video_Initiated")
                                        }
                                        href={`https://service.cloudninecare.com/video_chat/?mode=d&id=${ite.amsappointmentid}`}
                                        target="_blank"
                                        variant="contained"
                                        color="primary"
                                    >
                                        Start Video Consult
                                    </Button>
                                </div>
                                : null
                    } */}

          {ite.status === "checkedout" && (
            <div style={{ marginLeft: "7vw" }}>
              <button
                style={{
                  marginTop: "1.9rem",
                  cursor: "pointer",
                  border: "0.1rem solid #851e87",
                  width: "12rem",
                  height: "2.1rem",
                  borderRadius: "0.7rem",
                  color: "#851e87",
                  backgroundColor: "white",
                  fontSize: "0.8rem",
                  fontWeight: "bold",
                }}
                onClick={() =>
                  this.handleGenerateAndSendPrescription(
                    ite.mpid,
                    ite.patientemail,
                    ite.mobile,
                    ite.patientname,
                    this.state.doctorName,
                    ite.amsappointmentid,
                    ite.sitecode,
                    ite.prescriptionmessage
                      ? ite.prescriptionmessage.replace(/\n/g, "###")
                      : ite.prescriptionmessage,
                    ite.date_of_appointment,
                    ite.ltappointmentid
                  )
                }
              >
                Send Prescription
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );

  handleOnMyPastApptHistoryPress = (doctorId) => {
    this.setState({
      initialPageLoading: true,
    });
    fetchVideoConsultationHistory(doctorId).then((data) => {
      // console.log(data);
      if (data && data.length) {
        // console.log(data);
        const todaysDate = moment().format("YYYY-MM-DD");
        const pastDate = moment().subtract(7, "days").format("YYYY-MM-DD");
        const pastAppointments = data.filter(
          (item) =>
            moment(item.date_of_appointment) < moment(todaysDate) &&
            moment(item.date_of_appointment) >= moment(pastDate) &&
            item.status !== "cancelled"
        );
        const sortedPastAppointments = pastAppointments.sort(
          (itema, itemb) =>
            moment(
              `${itemb.date_of_appointment} ${itemb.time_of_appointment}`,
              "YYYY-MM-DD hh:mm A"
            ) -
            moment(
              `${itema.date_of_appointment} ${itema.time_of_appointment}`,
              "YYYY-MM-DD hh:mm A"
            )
        );
        console.log(sortedPastAppointments);
        this.setState({
          pastAppointments: sortedPastAppointments,
          initialPageLoading: false,
        });
      } else {
        this.setState({ initialPageLoading: false });
        this.showToast(
          "Network error. Try again",
          CONSTANTS.TOAST_SEVERITY.ERROR
        );
      }
    });
  };

  setValue = (e) => {
    // console.log("search");
    this.setState({ searchValue: e.target.value });
  };

  clearSearch = (e) => {
    if (this.state.isSearchFilter) {
      this.setState({ isSearchFilter: false, searchPastHistory: [] });
    }
    this.setState({ searchValue: "" });
  };

  handleChangeSearch = (e) => {
    if (this.state.searchValue !== "") {
      e.preventDefault();
      this.setState({
        isSearchFilter: true,
        loading: true,
      });
      const searchValue = this.state.searchValue.trim().split("/").join("-");
      console.log(searchValue);
      // patientname
      if (searchValue) {
        var output = this.state.pastAppointments.filter(
          (key) => key.mobile === searchValue || key.mpid === searchValue
        );

        if (output.length === 0) {
          output = this.state.pastAppointments.filter((user) => {
            return user.patientname
              .toLowerCase()
              .startsWith(searchValue.toLowerCase());
          });
        }
        if (output.length)
          this.setState({ searchPastHistory: output, loading: false });
        else {
          this.setState({ loading: false, searchPastHistory: [] });
          this.showToast(
            "No Appoitments Found With Given mpid or phone number in Past 7 days",
            CONSTANTS.TOAST_SEVERITY.INFO
          );
        }
      }
    }
  };

  render() {
    const { classes } = this.props;
    const sendSMSModal = (
      <Dialog
        open={this.state.showSMSModalBool}
        onClose={() => this.sendSMSModalfn(false)}
        style={{ padding: "5rem 5rem" }}
      >
        <DialogTitle
          disableTypography
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h4>Send SMS & Notification To {this.state.selectCustomerMobile}</h4>
          <IconButton onClick={() => this.sendSMSModalfn(false)}>
            <CloseIcon color={"grey"} />
          </IconButton>
        </DialogTitle>

        <TextareaAutosize
          style={{ margin: "0.5rem 1.5rem", width: "30rem", height: "20rem" }}
          // rowsMin={15}
          value={this.state.smsMessageText}
          onChange={this.smsMessageTextValueChangeFn}
        ></TextareaAutosize>

        <div style={{ textAlign: "right", padding: "1rem 2rem" }}>
          <button
            style={{
              marginLeft: "0.5rem",
              cursor: "pointer",
              border: "0.1rem solid #851e87",
              minWidth: "14rem",
              height: "2.1rem",
              borderRadius: "0.7rem",
              color: "white",
              backgroundColor: "#851e87",
              fontSize: "0.8rem",
              fontWeight: "1rem",
            }}
            onClick={() => this.sendSMSNotification()}
          >
            SEND SMS & NOTIFICATION{" "}
          </button>
        </div>
      </Dialog>
    );
    //  Code Emds

    const EditPrescriptionModal = (
      <Dialog
        style={{ padding: "5rem 5rem" }}
        open={this.state.shouldShowEditPrescriptionModal}
        onClose={() => {
          this.hideEditPrescriptionModal();
        }}
      >
        <DialogTitle
          disableTypography
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h4>Edit Prescription</h4>
          <IconButton onClick={() => this.hideEditPrescriptionModal()}>
            <CloseIcon color={"grey"} />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <TextareaAutosize
            style={{ width: "32rem", height: "15rem" }}
            // rowsMin={10}
            disabled
            value={
              this.state.appointmentDataForEditingPrescription
                .prescriptionmessage
            }
          ></TextareaAutosize>
          <br />
          <br />

          <h4>Add new details to prescription</h4>
          <TextareaAutosize
            style={{
              // width: "100%",
              margin: "1rem 0",
              width: "32rem",
              height: "10rem",
            }}
            // rowsMin={10}
            value={this.state.editedPrescriptionText}
            onChange={this.handleOnChangePrescriptionText}
          ></TextareaAutosize>
        </DialogContent>
        <DialogActions>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "1rem 2rem",
            }}
          >
            <button
              style={{
                marginRight: "0.5rem",
                cursor: "pointer",
                border: "0.1rem solid #851e87",
                minWidth: "15.5rem",
                height: "2.1rem",
                borderRadius: "0.7rem",
                color: "#851e87",
                backgroundColor: "white",
                fontSize: "0.8rem",
                fontWeight: "bold",
              }}
              onClick={() => this.hideEditPrescriptionModal()}
            >
              Dismiss
            </button>
            <button
              style={{
                marginLeft: "0.5rem",
                cursor: "pointer",
                border: "0.1rem solid #851e87",
                minWidth: "15.5rem",
                height: "2.1rem",
                borderRadius: "0.7rem",
                color: "white",
                backgroundColor: "#851e87",
                fontSize: "0.8rem",
                fontWeight: "1rem",
              }}
              onClick={() => this.handleSavePrescription()}
            >
              Save
            </button>
          </div>
        </DialogActions>
        <Loader loading={this.state.savePrescriptionMessageLoading} />
      </Dialog>
    );

    //  Code Ends
    return (
      <div style={{ padding: "1.2rem 1.7rem" }}>
        <Paper style={{ minHeight: "80vh" }}>
          <div
            style={{ display: "flex", alignItems: "center", padding: "1rem 0" }}
          >
            <h4 style={{ marginLeft: "1rem" }}>
              {" "}
              Past Appointments: {moment().format("DD MMM, YY")} -{" "}
              {moment().subtract(7, "days").format("DD MMM, YY")}{" "}
            </h4>
            <form
              onSubmit={this.handleChangeSearch}
              style={{ display: "flex", paddingBottom: "10px", flexGrow: "8" }}
            >
              <TextField
                id="search"
                style={{ margin: "0 1rem", marginTop: "0.5rem" }}
                className={classes.root}
                variant="outlined"
                type={"text"}
                size="small"
                placeholder="Seach past 7 days appointments by name or mpid/phone no."
                value={this.state.searchValue}
                onChange={this.setValue}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Search"
                        onClick={(e) => this.clearSearch(e)}
                      >
                        <Clear />
                      </IconButton>
                      <IconButton
                        aria-label="Search"
                        onClick={(e) => this.handleChangeSearch(e)}
                      >
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </form>
          </div>
          <Divider />
          <br></br>
          <div>
            {this.state.isSearchFilter ? (
              this.state.loading ? (
                <CircularProgress
                  style={{ margin: "0 45%" }}
                  color="secondary"
                />
              ) : this.state.searchPastHistory.length === 0 ? (
                <div>
                  {/* <h4>Searched Appointment</h4>
                                        <Divider
                                            style={{
                                                marginTop: 10,
                                                marginBottom: 10,
                                            }}
                                        /> */}
                  <h4 style={{ textAlign: "center" }}>
                    No Appointments Found With Given name or mpid/phone no. in
                    Past 7 days
                  </h4>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              ) : (
                <>
                  {/* <h4 style={{textAlign: 'center',marginTop: '-0.75rem'}}>Searched Result</h4>
                                        <br/> */}
                  {this.state.searchPastHistory.map((ite, index) => {
                    return (
                      <div key={`${index}b`}>
                        {this.getRowUIOfAppointment(ite)}
                      </div>
                    );
                  })}
                </>
              )
            ) : this.state.pastAppointments ? (
              this.state.pastAppointments.map((ite, index) => {
                return (
                  <div key={`${index}b`}>{this.getRowUIOfAppointment(ite)}</div>
                );
              })
            ) : (
              <div>
                <h4>Past 7-day appointments</h4>
                <Divider
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                />
                <h4>No appointments found</h4>
              </div>
            )}
          </div>
          {EditPrescriptionModal}
          {sendSMSModal}
          <Loader loading={this.state.initialPageLoading} />
          <Toast
            visible={this.state.shouldShowToast}
            severity={this.state.toastSeverity}
            message={this.state.toastMessage}
            handleClose={this.hideToast}
          />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(History7days);
