// This project is created by krishna gurram  on 12 April 2019
import React, { Component } from "react";
import Paper from "@mui/material/Paper";
// import { showWindowAlert } from "../Utils/utility";
import amplitude from "amplitude-js";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@mui/material";
import { withStyles } from "@mui/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Toast } from "../components/GenericComponents";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import TextField from "@material-ui/core/TextField";
import { CONSTANTS } from "../Constants";

const styles = (theme) => ({
  root: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#99219b",
    },

    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#99219b",
    },
  },
});

class QuickConsultation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      doctorId: "",
      doctorName: "",
      doctorMobile: "",
      abbreviation: "",
      loading: true,
      enableEdit: false,
      shouldShowToast: false,
      toastMessage: "",
      toastSeverity: "",
      quickurl: null,
      toggle: false,
      copied: false,
      sendModalOpen: false,
      CustomerMobile: null,
      smsMessageText: "The doctor is waiting for you to join the consultation.",
    };
  }

  componentDidMount() {
    this.setState({
      doctorId: localStorage.getItem("d_id"),
      doctorName: localStorage.getItem("d_name"),
      doctorMobile: localStorage.getItem("d_mobile"),
    });

    amplitude
      .getInstance()
      .init("829c5b10e843e0edefdc4d33f04ff290", localStorage.getItem("d_name"));
    this.fetchDoctorProfile();
  }

  isAuthorized = () => {
    const doctorId = localStorage.getItem("d_id");
    const doctorName = localStorage.getItem("d_name");
    const doctorMobile = localStorage.getItem("d_mobile");
    return doctorId && doctorName && doctorMobile ? true : false;
  };

  fetchDoctorProfile() {
    const url =
      "https://cx-server2.cloudninecare.com/qkams/get/doctor/profile/?docId=" +
      localStorage.getItem("d_id");

    fetch(url)
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.status === 1) {
          this.setState({
            dLifetrenzId: resp.data[0].lifetrenzId,
            dSiteCode: resp.data[0].getHospitals[0].siteCode,
            dClinicname: resp.data[0].getHospitals[0].name,
          });
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  }

  handleQuickConsultBook = () => {
    const url =
      "https://cxuserdb.cloudninecare.email/videoconsultation/quickconsult/create";
    const bodyObj = {
      amsdoctorid: this.state.doctorId,
      sitecode: this.state.dSiteCode,
      doctorname: this.state.doctorName,
      doctormobile: this.state.doctorMobile,
      clinicname: this.state.dClinicname,
      ltdoctorid: this.state.dLifetrenzId,
    };
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(bodyObj),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.status === 1) {
          this.setState({ toggle: true, quickurl: resp.url });
          amplitude.getInstance().logEvent("Quick_Link_Generated");
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  handleClose = () => {
    this.setState({
      sendModalOpen: false,
      CustomerMobile: null,
      smsMessageText: "The doctor is waiting for you to join the consultation.",
    });
  };

  handleTextChange = (event) =>
    this.setState({ smsMessageText: event.target.value });

  handleInputContact = (e) => {
    // console.log(e.target.value);
    this.setState({ CustomerMobile: e.target.value });
  };

  showToast = (message, alertType) => {
    this.setState({
      shouldShowToast: true,
      toastSeverity: alertType,
      toastMessage: message,
    });
  };

  sendSMSNotification = () => {
    let mobile = this.state.CustomerMobile;

    let smsText = `${this.state.quickurl} ${this.state.smsMessageText}`;
    if (mobile === null) {
      return;
    }

    const qresult = window.confirm(
      "Are you sure send SMS & Notification to Customer?"
    );

    if (qresult) {
      this.initiateSMS(mobile, smsText)
        .then((res) => {
          if (res && res.status && res.status === 1) {
            this.showToast(
              "SMS is send to Cutomer",
              CONSTANTS.TOAST_SEVERITY.SUCCESS
            );
            amplitude.getInstance().logEvent("send_sms_now_success", {
              mobile: mobile,
              sms: smsText,
            });
          } else {
            this.showToast(
              "SMS was not sent due to some error",
              CONSTANTS.TOAST_SEVERITY.ERROR
            );
            amplitude.getInstance().logEvent("send_sms_now_failed", {
              mobile: mobile,
              sms: smsText,
              error: res,
            });
          }
        })
        .catch((error) => {
          this.showToast(
            "SMS was not sent due to some error",
            CONSTANTS.TOAST_SEVERITY.ERROR
          );
          amplitude.getInstance().logEvent("send_sms_now_failed", {
            mobile: mobile,
            sms: smsText,
            error: error,
          });
        });

      this.intiateNotification(mobile, smsText)
        .then((res) => {
          if (res && res.status && res.status === 1) {
            this.showToast(
              "Notification is send to Cutomer",
              CONSTANTS.TOAST_SEVERITY.SUCCESS
            );
            amplitude.getInstance().logEvent("send_notification_now_success", {
              mobile: mobile,
              sms: smsText,
            });
          } else {
            this.showToast(
              "Notification was not sent due to some error",
              CONSTANTS.TOAST_SEVERITY.ERROR
            );
            amplitude.getInstance().logEvent("send_notification_now_failed", {
              mobile: mobile,
              sms: smsText,
              error: res,
            });
          }
        })
        .catch((error) => {
          this.showToast(
            "Notification was not sent due to some error",
            CONSTANTS.TOAST_SEVERITY.ERROR
          );
          amplitude.getInstance().logEvent("send_notification_now_failed", {
            mobile: mobile,
            sms: smsText,
            error: error,
          });
        });
      this.setState({
        sendModalOpen: false,
        CustomerMobile: null,
        smsMessageText:
          "The doctor is waiting for you to join the consultation.",
      });
    }
  };

  initiateSMS = (toMobile, message) => {
    const url = `https://cxuserdb.cloudninecare.email/videoconsultation/sendsms`;
    const req_body = {
      sendTo: toMobile,
      message: message,
    };
    const fetchOpts = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(req_body),
    };
    return fetch(url, fetchOpts)
      .then((response) => response.json())
      .catch((error) => {});
  };

  intiateNotification = (mobile, message) => {
    const url =
      "https://cxuserdb.cloudninecare.email/miscellaneous/send/notification/" +
      mobile +
      "/";
    const req_body = {
      title: "Message from " + this.state.doctorName,
      message: message,
    };
    const fetchOpts = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(req_body),
    };

    return fetch(url, fetchOpts)
      .then((response) => response.json())
      .catch((error) => {});
  };

  hideToast = (event, reason) => {
    if (reason === "clickaway") return;
    this.setState({
      shouldShowToast: false,
      toastSeverity: "",
      toastMessage: "",
    });
  };

  shareLinkModalOpen = () => {
    const el = document.createElement("input");
    console.log(this.state.quickurl);
    el.value = this.state.quickurl;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    this.setState({ sendModalOpen: true });
  };

  render() {
    const { toggle, quickurl } = this.state;
    const { classes } = this.props;

    const sendSmsModal = (
      <Dialog
        open={this.state.sendModalOpen}
        onClose={() => this.handleClose()}
        style={{ margin: "5em" }}
      >
        <DialogTitle
          disableTypography
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h4>Share Link With Customer</h4>
          <IconButton onClick={() => this.handleClose()}>
            <CloseIcon color={"grey"} />
          </IconButton>
        </DialogTitle>

        <div style={{ margin: "0 1.3rem" }}>
          <p>{"Copied Link"}</p>
          <a
            target="_blank"
            href={quickurl}
            rel="noreferrer"
            style={{ color: "#851e87" }}
          >
            {quickurl}
          </a>
        </div>

        <br />
        <div style={{ margin: "0 1.3rem" }}>
          <p>{"Send To"}</p>
          <TextField
            style={{ margin: "0.3rem 0", width: "35rem" }}
            className={classes.root}
            onChange={(event) => this.handleInputContact(event)}
            value={this.state.contact}
            id="outlined"
            size="small"
            label="Enter Customer's Mobile Number"
            variant="outlined"
          />
        </div>
        <br />
        <div style={{ margin: "0 1.3rem" }}>
          <p>{"Message"}</p>
          <TextareaAutosize
            style={{
              // resize: 'none',
              width: "34rem",
              padding: "0.7rem",
              borderColor: "#e9e9e9",
              backgroundColor: "#fcfcfc",
            }}
            onChange={(event) => this.handleTextChange(event)}
            autoFocus
            rowsMin={6}
            id="name"
            label="Template"
            value={this.state.smsMessageText}
          />
        </div>

        <DialogActions>
          <button
            style={{
              margin: "0.5rem 1rem",
              cursor: "pointer",
              textTransform: "none",
              border: "0.1rem solid #99219b",
              width: "40rem",
              height: "2.2rem",
              borderRadius: "0.5rem",
              color: "white",
              backgroundColor: "#99219b",
              fontSize: "0.9rem",
            }}
            onClick={() => this.sendSMSNotification()}
            autoFocus
          >
            Send SMS And Notification
          </button>
        </DialogActions>
      </Dialog>
    );

    if (!this.isAuthorized()) {
      return <p>Your are not authorized</p>;
    } else {
      return (
        <div style={{ padding: "1.2rem 1.7rem" }}>
          <Paper>
            {
              //pop to generate link
              !toggle ? (
                <div style={{ textAlign: "center", padding: "9rem 0" }}>
                  <h3 style={{ padding: "1rem 0" }}>
                    Are you sure you want to create a Quick Consult ?
                  </h3>
                  <p>
                    A payment link will be sent to customer, post payment it
                    will redirect to video Consultation
                  </p>
                  <br />
                  <div>
                    <button
                      style={{
                        marginLeft: "1vw",
                        cursor: "pointer",
                        border: "0.1rem solid #851e87",
                        minWidth: "17vw",
                        height: "2.1rem",
                        borderRadius: "0.7rem",
                        color: "white",
                        backgroundColor: "#851e87",
                        fontSize: "0.9rem",
                        fontWeight: "1rem",
                      }}
                      onClick={this.handleQuickConsultBook}
                    >
                      Ok
                    </button>
                  </div>
                </div>
              ) : (
                //Link generated
                <div style={{ textAlign: "center", padding: "9rem 0" }}>
                  <h3 style={{ padding: "1rem 0" }}>
                    Quick consultation link is generated. Copy and share the
                    link with the customers.
                  </h3>
                  <a
                    target="_blank"
                    href={quickurl}
                    style={{ color: "#851e87" }}
                    rel="noreferrer"
                  >
                    {quickurl}
                  </a>
                  <br />
                  <br />
                  <div>
                    <button
                      style={{
                        marginRight: "1vw",
                        cursor: "pointer",
                        border: "0.1rem solid #851e87",
                        minWidth: "17vw",
                        height: "2.1rem",
                        borderRadius: "0.7rem",
                        color: "#851e87",
                        backgroundColor: "white",
                        fontSize: "0.8rem",
                        fontWeight: "bold",
                      }}
                      onClick={() => {
                        this.setState({ toggle: false, copied: false });
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      style={{
                        marginLeft: "1vw",
                        cursor: "pointer",
                        border: "0.1rem solid #851e87",
                        minWidth: "17vw",
                        height: "2.1rem",
                        borderRadius: "0.7rem",
                        color: "white",
                        backgroundColor: "#851e87",
                        fontSize: "0.9rem",
                        fontWeight: "1rem",
                      }}
                      onClick={() => {
                        this.shareLinkModalOpen();
                      }}
                    >
                      {"Share Link"}
                    </button>
                  </div>
                </div>
              )
            }

            {sendSmsModal}
            <Toast
              visible={this.state.shouldShowToast}
              severity={this.state.toastSeverity}
              message={this.state.toastMessage}
              handleClose={this.hideToast}
            />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </Paper>
        </div>
      );
    }
  }
}

export default withStyles(styles)(QuickConsultation);
