import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./Main.scss";
import Header from "../Header/Header";
import { Route, Switch } from "react-router-dom";
import UpcomingAppointments from "../../pages/UpcomingAppointments";
import History7days from "../../pages/History7days";
import MyTemplates from "../../pages/MyTemplates";
import MyNote from "../../pages/MyNote";
import QuickConsultation from "../../pages/QuickConsultation";
import IpConsultation from "../../pages/IpConsultation";
import FutureAppointments from "../../pages/FutureAppointments";

function Main(props) {
  const history = useHistory();
  const isAuthorized = () => {
    const doctorId = localStorage.getItem("d_id");
    const doctorName = localStorage.getItem("d_name");
    const doctorMobile = localStorage.getItem("d_mobile");
    return doctorId && doctorName && doctorMobile ? true : false;
  };

  useEffect(() => {
    if (!isAuthorized()) history.push("/login");
    else if (history.location.pathname === "/")
      history.push("/today-appointments");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`main__container active`}>
      <Header props={history} />
      <Switch>
        <Route path="/today-appointments" exact>
          <UpcomingAppointments props={history} />
        </Route>
        <Route path="/history-7-days" exact>
          <History7days props={history} />
        </Route>
        <Route path="/future-appointments" exact>
          <FutureAppointments props={history} />
        </Route>
        <Route path="/my-templates" exact>
          <MyTemplates props={history} />
        </Route>
        <Route path="/my-note" exact>
          <MyNote props={history} />
        </Route>
        <Route path="/quick-consultation" exact>
          <QuickConsultation props={history} />
        </Route>
        <Route path="/ip-consultation" exact>
          <IpConsultation props={history} />
        </Route>
      </Switch>
      {/* </div> */}
    </div>
  );
}
export default Main;
