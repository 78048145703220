import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import "./SideNav.scss";
import { sideNavData } from "./sideNavData";
import { Link } from "react-router-dom";
import Logo from "../../images/compLogo.png";

function SideNav() {
  const [clicked, setClicked] = useState(0);
  const history = useHistory();
  useEffect(() => {
    if (history.location.pathname === "/history-7-days") setClicked(1);
    if (history.location.pathname === "/future-appointments") setClicked(2);
    if (history.location.pathname === "/my-templates") setClicked(3);
    if (history.location.pathname === "/my-note") setClicked(4);
    if (history.location.pathname === "/quick-consultation") setClicked(5);
    if (history.location.pathname === "/ip-consultation") setClicked(6);
  }, []);

  const clickFunc = (index) => {
    setClicked(index);
  };

  return (
    <div className={`wrapper active`}>
      <div className={`sidenav`}>
        <div className="sidenav__logo__box">
          <img src={Logo} alt="logo" />
        </div>
        <nav>
          <ul>
            {sideNavData.map((item, index) => {
              return (
                <li
                  style={{
                    backgroundColor: index === clicked ? "#99219b" : "",
                  }}
                  key={index}
                  onClick={() => {
                    clickFunc(index);
                  }}
                >
                  <Link to={item.route} className="link">
                    <img
                      src={index === clicked ? item.ListImage2 : item.ListImage}
                      alt="item"
                    />
                    <span
                      style={{ color: index === clicked ? "white" : "" }}
                      className="list_text"
                    >
                      {item.ListItem}
                    </span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default SideNav;
