// This project is created by krishna gurram  on 12 April 2019
import React, { Component } from "react";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import TextField from "@material-ui/core/TextField";
// import CircularProgress from '@material-ui/core/CircularProgress';
import { fetchTemplates, postLabScanToTemplates } from "../Api/Api";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Toast } from "../components/GenericComponents";
import { getDocDetails, getLabScanDetails } from "./../Api/Api";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Paper from "@mui/material/Paper";
import { CONSTANTS } from "../Constants";

import EditLogo from "../images/Edit.png";
import DeleteLogo from "../images/Delete.png";

import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@mui/material";
import { withStyles } from "@mui/styles";

const styles = (theme) => ({
  root: {
    //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    //     borderColor: "green"
    //   },
    //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    //     borderColor: "red"
    //   },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#99219b",
    },
    //   "& .MuiOutlinedInput-input": {
    //     color: "green"
    //   },
    //   "&:hover .MuiOutlinedInput-input": {
    //     color: "red"
    //   },
    //   "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
    //     color: "red"
    //   },
    //   "& .MuiInputLabel-outlined": {
    //     color: "green"
    //   },
    //   "&:hover .MuiInputLabel-outlined": {
    //     color: "red"
    //   },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#99219b",
    },
  },
});

class MyTemplates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      doctorId: "",
      doctorName: "",
      doctorMobile: "",
      templates: [],
      loading: true,
      openEditModal: false,
      openCreateModal: false,
      edittemplatetext: "",
      selectedid: "",
      shouldShowToast: false,
      toastMessage: "",
      createTemplateName: "",
      doctorDetails: {},
      labscanDump: [],
      labscanDump1: [],
      seletectedidlabtemplate: [],
      toastSeverity: "",
      selectedtemplate: null,
      boolSelect: false,
    };
    this.setIntervalId = "";
  }

  handleClose = () =>
    this.setState({ openEditModal: false, openCreateModal: false });

  showToast = (message, alertType) => {
    this.setState({
      shouldShowToast: true,
      toastSeverity: alertType,
      toastMessage: message,
    });
  };

  hideToast = () => this.setState({ shouldShowToast: false, toastMessage: "" });

  handleEditTemp = (event) =>
    this.setState({ edittemplatetext: event.target.value });

  handleCreateButton = () => this.setState({ openCreateModal: true });

  handleCreateTempName = (event) =>
    this.setState({ createTemplateName: event.target.value });

  handleSelectEdit = (item, index) => {
    this.setState({
      selectedid: item.id,
      edittemplatetext: item.templatecontent,
      seletectedidlabtemplate: item.labtemplate ? item.labtemplate : [],
      openEditModal: true,
    });
  };

  handleSelectDel = (ite) => {
    let response = window.confirm("are you sure to delete this template");
    if (response) {
      // console.log(ite);
      const url =
        "https://cxuserdb.cloudninecare.email/videoconsultation/delete/templates/";
      const bodyObj = { id: ite.id };

      const fetchOpts = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(bodyObj),
      };
      fetch(url, fetchOpts)
        .then((res) => res.json())
        .then((res1) => {
          if (res1.status === 1) {
            this.showToast(
              "Template deleted successfully",
              CONSTANTS.TOAST_SEVERITY.SUCCESS
            );
            this.refreshThePageSilently();
          }
        });
      if (this.state.boolSelect) {
        this.setState({ boolSelect: false, selectedtemplate: null });
      }
    }
  };

  handleEditSave = () => {
    // console.log(this.state.edittemplatetext);
    this.savetoDB(this.state.selectedid, this.state.edittemplatetext).then(
      (res) => {
        // console.log(res, res.status);
        if (res.status === 1) {
          this.showToast("Template saved", CONSTANTS.TOAST_SEVERITY.SUCCESS);
          this.refreshThePageSilently();
        }
      }
    );
    this.setState({
      openEditModal: false,
    });
  };

  componentDidMount() {
    this.setState(
      {
        doctorId: localStorage.getItem("d_id"),
        doctorName: localStorage.getItem("d_name"),
        doctorMobile: localStorage.getItem("d_mobile"),
      },
      () => this.refreshThePageSilently()
    );

    // this.setIntervalId = setInterval(() => {
    //     this.refreshThePageSilently();
    // }, 30000);

    const docamsid = localStorage.getItem("d_id");
    getDocDetails(docamsid).then((res) => {
      if (res && res.data) {
        this.setState({
          doctorDetails: res.data[0],
        });

        getLabScanDetails(res.data[0].getHospitals[0].siteCode).then((res) => {
          this.setState({ labscanDump: res });
        });
        if (res.data[0].getHospitals[1]) {
          getLabScanDetails(res.data[0].getHospitals[1].siteCode).then(
            (res) => {
              this.setState({ labscanDump1: res });
            }
          );
        }
      } else {
        //response failed
        this.showToast(
          "Network error. Please try again",
          CONSTANTS.TOAST_SEVERITY.ERROR
        );
      }
    });
  }

  refreshThePageSilently = () => {
    this.getTemplates(this.state.doctorId);
  };

  getTemplates = (doctorId) => {
    fetchTemplates(doctorId).then((res) => {
      if (res && res.data) {
        if (this.state.boolSelect)
          this.setState({ selectedtemplate: res.data[this.state.index] });
        else this.setState({ templates: res.data });
      } else {
        //response failed
        this.showToast(
          "Network error. Please try again",
          CONSTANTS.TOAST_SEVERITY.ERROR
        );
      }
    });
  };

  savetoDB = (id, message) => {
    const url =
      "https://cxuserdb.cloudninecare.email/videoconsultation/post/templates/";
    const bodyObj = {
      id: id,
      templatecontent: message,
    };

    const fetchOpts = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(bodyObj),
    };
    return fetch(url, fetchOpts).then((res) => res.json());
  };

  handleCreateSave = () => {
    if (this.state.createTemplateName === "") {
      this.showToast(
        "Please Enter template name",
        CONSTANTS.TOAST_SEVERITY.WARNING
      );
      return;
    }
    const url =
      "https://cxuserdb.cloudninecare.email/videoconsultation/create/templates/";
    const bodyObj = {
      templatecontent: this.state.edittemplatetext,
      amsdoctorid: this.state.doctorId,
      templatename: this.state.createTemplateName,
    };
    const fetchOpts = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(bodyObj),
    };

    // console.log(bodyObj);
    fetch(url, fetchOpts)
      .then((res) => res.json())
      .then((resp) => {
        if (resp.status === 1) {
          this.showToast(
            "Template Created Successfully",
            CONSTANTS.TOAST_SEVERITY.SUCCESS
          );
          this.refreshThePageSilently();
        }
      });

    this.setState({
      createTemplateName: "",
      edittemplatetext: "",
    });
    this.handleClose();
  };

  stopSetInterval = () => {
    clearTimeout(this.setIntervalId);
  };

  isAuthorized = () => {
    const doctorId = localStorage.getItem("d_id");
    const doctorName = localStorage.getItem("d_name");
    const doctorMobile = localStorage.getItem("d_mobile");
    return doctorId && doctorName && doctorMobile ? true : false;
  };

  handlelabchange = (event, option, siteCode) => {
    // console.log(option);
    postLabScanToTemplates(
      siteCode,
      "lab",
      option,
      this.state.doctorId,
      this.state.selectedid
    );
    let tempObj = JSON.parse(
      JSON.stringify(this.state.seletectedidlabtemplate)
    );
    tempObj[siteCode] = option;
    this.setState({ seletectedidlabtemplate: tempObj });
  };

  handleSeletedSitecodeChange = () => {};

  handleTemplateChange = (e) => {
    if (e.target.value === -1) {
      this.setState({ boolSelect: false, selectedtemplate: null, index: -1 });
      return;
    }
    this.setState({
      boolSelect: true,
      selectedtemplate: this.state.templates[e.target.value],
      index: e.target.value,
    });
  };

  render() {
    const { boolSelect, selectedtemplate } = this.state;
    const { classes } = this.props;

    let defaultValue1, defaultValue2;
    if (this.state.doctorDetails.getHospitals) {
      // debugger;
      defaultValue1 =
        this.state.seletectedidlabtemplate[
          this.state.doctorDetails.getHospitals[0].siteCode
        ];
      defaultValue2 = this.state.doctorDetails.getHospitals[1]
        ? this.state.seletectedidlabtemplate[
            this.state.doctorDetails.getHospitals[1].siteCode
          ]
        : [];
    }
    const AlertDialog = (
      <Dialog
        fullScreen
        open={this.state.openEditModal}
        onClose={() => this.handleClose()}
        style={{ margin: "4em" }}
      >
        <DialogTitle
          disableTypography
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h4>Edit Template</h4>
          <IconButton onClick={() => this.handleClose()}>
            <CloseIcon color={"grey"} />
          </IconButton>
        </DialogTitle>
        <TextareaAutosize
          style={{
            padding: "1rem",
            margin: "0 1em",
            borderColor: "#e9e9e9",
            backgroundColor: "#fcfcfc",
            height: "10rem",
          }}
          onChange={(event) => this.handleEditTemp(event)}
          autoFocus
          minRows={60}
          id="name"
          label="Template"
          value={this.state.edittemplatetext}
        />
        <br />
        <h4 style={{ textAlign: "center" }}>
          Add/Edit Scan/labs for this template{" "}
        </h4>
        <br />

        <span style={{ marginLeft: "2em" }}>
          {this.state.doctorDetails.getHospitals
            ? this.state.doctorDetails.getHospitals[0].name
            : null}
        </span>

        <Autocomplete
          id="combo-box-demo"
          options={this.state.labscanDump}
          multiple
          getOptionLabel={(option) => option.name_of_service}
          style={{
            marginLeft: "2em",
            margin: "1em",
            marginBottom: "2em",
          }}
          defaultValue={defaultValue1}
          onChange={(event, option) =>
            this.handlelabchange(
              event,
              option,
              this.state.doctorDetails.getHospitals[0].siteCode
            )
          }
          renderInput={(params) => (
            <TextField
              {...params}
              className={classes.root}
              label="Lab/Scan"
              variant="outlined"
              size="small"
            />
          )}
        />
        <span style={{ marginLeft: "2em" }}>
          {this.state.doctorDetails.getHospitals &&
          this.state.doctorDetails.getHospitals.length > 1
            ? this.state.doctorDetails.getHospitals[1].name
            : null}
        </span>
        {this.state.doctorDetails.getHospitals &&
        this.state.doctorDetails.getHospitals.length > 1 ? (
          <Autocomplete
            id="combo-box-demo1"
            options={this.state.labscanDump1}
            multiple
            defaultValue={defaultValue2}
            getOptionLabel={(option) => option.name_of_service}
            style={{ marginLeft: "2em", margin: "1em" }}
            onChange={(event, option) =>
              this.handlelabchange(
                event,
                option,
                this.state.doctorDetails.getHospitals[1].siteCode
              )
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Lab"
                className={classes.root}
                size="small"
                variant="outlined"
              />
            )}
          />
        ) : null}

        <DialogActions>
          <button
            style={{
              margin: "0.5rem 1rem",
              cursor: "pointer",
              textTransform: "none",
              border: "0.1rem solid #99219b",
              width: "6rem",
              height: "1.9rem",
              borderRadius: "0.5rem",
              color: "white",
              backgroundColor: "#99219b",
              fontSize: "0.8rem",
            }}
            onClick={() => this.handleEditSave()}
            color="primary"
            autoFocus
          >
            Save
          </button>
        </DialogActions>
      </Dialog>
    );

    const CreateNewTemplateDialog = (
      <Dialog
        fullScreen
        open={this.state.openCreateModal}
        onClose={() => this.handleClose()}
        style={{ margin: "5em" }}
      >
        <DialogTitle
          disableTypography
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h4>Create Template</h4>
          <IconButton onClick={() => this.handleClose()}>
            <CloseIcon color={"grey"} />
          </IconButton>
        </DialogTitle>

        <TextField
          style={{ margin: "1em" }}
          className={classes.root}
          onChange={(event) => this.handleCreateTempName(event)}
          value={this.state.createTemplateName}
          id="outlined"
          size="small"
          label="Template Name"
          variant="outlined"
        />

        <TextareaAutosize
          style={{
            // resize: 'none',
            padding: "1rem",
            margin: "1em",
            borderColor: "#e9e9e9",
            backgroundColor: "#fcfcfc",
          }}
          onChange={(event) => this.handleEditTemp(event)}
          autoFocus
          minRows={45}
          id="name"
          label="Template"
          value={this.state.edittemplatetext}
        />

        <DialogActions>
          <button
            style={{
              margin: "0.5rem 1rem",
              cursor: "pointer",
              textTransform: "none",
              border: "0.1rem solid #99219b",
              width: "7rem",
              height: "1.9rem",
              borderRadius: "0.5rem",
              color: "white",
              backgroundColor: "#99219b",
              fontSize: "0.8rem",
            }}
            onClick={() => this.handleCreateSave()}
            color="primary"
            autoFocus
          >
            Create
          </button>
        </DialogActions>
      </Dialog>
    );

    if (!this.isAuthorized()) {
      return <p>Your are not authorized</p>;
    } else {
      // const that = this;
      return (
        <div style={{ padding: "1.2rem 1.7rem" }}>
          <Paper style={{ minHeight: "80vh" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "1rem 0",
              }}
            >
              <div style={{ display: "flex" }}>
                <h3 style={{ margin: "0 1rem" }}>Template Listing</h3>

                <select
                  style={{
                    borderRadius: "0.5rem",
                    height: "2rem",
                    border: "1px solid #D3D3D3",
                  }}
                  onChange={this.handleTemplateChange.bind(this)}
                >
                  <option value={-1}> All Templates </option>
                  {this.state.templates.map((template, index) => (
                    <option key={index} value={index}>
                      {template.templatename}
                    </option>
                  ))}
                </select>
              </div>
              <button
                onClick={() => this.handleCreateButton()}
                style={{
                  cursor: "pointer",
                  margin: "0 1rem",
                  border: "0.1rem solid #99219b",
                  width: "11rem",
                  height: "2.1rem",
                  borderRadius: "0.5rem",
                  color: "white",
                  backgroundColor: "#99219b",
                  fontSize: "0.9rem",
                }}
              >
                + &nbsp; Create Template
              </button>
            </div>
            {AlertDialog}
            {CreateNewTemplateDialog}
            {!boolSelect && (
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {this.state.templates.length > 0 ? (
                  this.state.templates.map((ite, index) => {
                    return (
                      <div
                        key={index}
                        style={{
                          width: "34vw",
                          margin: "1.2vw",
                          border: "1px solid #D3D3D3",
                          borderRadius: "0.6vw",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            padding: "1vw",
                            justifyContent: "space-between",
                          }}
                        >
                          <h3 style={{ fontSize: "1.1rem" }}>
                            {ite.templatename}
                          </h3>
                          <div>
                            <img
                              onClick={() => this.handleSelectEdit(ite)}
                              style={{
                                cursor: "pointer",
                                width: "1.7vw",
                                height: "1.7vw",
                                objectFit: "cover",
                                margin: "0 1.5vw",
                              }}
                              src={EditLogo}
                              alt="item"
                            />
                            <img
                              onClick={() => this.handleSelectDel(ite)}
                              style={{
                                cursor: "pointer",
                                width: "1.7vw",
                                height: "1.7vw",
                                objectFit: "cover",
                              }}
                              src={DeleteLogo}
                              alt="item"
                            />
                          </div>
                        </div>

                        <div>
                          <TextareaAutosize
                            disabled
                            value={ite.templatecontent}
                            style={{
                              resize: "none",
                              padding: "1vw",
                              borderColor: "white",
                              backgroundColor: "white",
                              width: "31.5vw",
                              height: "15vw",
                            }}
                          ></TextareaAutosize>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <h4 style={{ marginLeft: "42%" }}>No Templates</h4>
                )}
              </div>
            )}
            {boolSelect && (
              <div
                style={{
                  width: "36.2vw",
                  margin: "1.2vw",
                  border: "1px solid #D3D3D3",
                  borderRadius: "0.5vw",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    padding: "1vw",
                    justifyContent: "space-between",
                  }}
                >
                  <h3>{selectedtemplate.templatename}</h3>
                  <div>
                    <img
                      onClick={() => this.handleSelectEdit(selectedtemplate)}
                      style={{
                        cursor: "pointer",
                        width: "1.5vw",
                        height: "1.5vw",
                        objectFit: "cover",
                        margin: "0 1.5vw",
                      }}
                      src={EditLogo}
                      alt="selectedtemplatem"
                    />
                    <img
                      onClick={() => this.handleSelectDel(selectedtemplate)}
                      style={{
                        cursor: "pointer",
                        width: "1.5vw",
                        height: "1.5vw",
                        objectFit: "cover",
                      }}
                      src={DeleteLogo}
                      alt="selectedtemplatem"
                    />
                  </div>
                </div>

                <div>
                  <TextareaAutosize
                    disabled
                    value={selectedtemplate.templatecontent}
                    style={{
                      resize: "none",
                      padding: "1vw",
                      borderColor: "white",
                      backgroundColor: "white",
                      width: "36vw",
                    }}
                  ></TextareaAutosize>
                </div>
              </div>
            )}
            {/* <Loader loading={this.state.loading}/> */}
            <Toast
              visible={this.state.shouldShowToast}
              severity={this.state.toastSeverity}
              message={this.state.toastMessage}
              handleClose={this.hideToast}
            />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </Paper>
        </div>
      );
    }
  }
}

export default withStyles(styles)(MyTemplates);
