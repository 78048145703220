import amplitude from "amplitude-js";
import moment from "moment";
import React, { useState } from "react";
import Switch from 'react-ios-switch';
import { useHistory } from 'react-router-dom';
import { CONSTANTS } from "../../Constants";
import danger from '../../images/Danger1.png';
import logout from '../../images/Logout1.png';
import { showWindowAlert } from "../../Utils/utility";
import { Toast } from "../GenericComponents";
import './Header.scss';

function Header() {

    const history = useHistory();
    const [dIN , setDIN] = useState(false);
    const [shouldShowToast,setShouldShowToast] = useState(false);
    const [toastSeverity,setToastSeverity]= useState("");
    const [toastMessage,setToastMessage]= useState("");

    const handleOnLogoutPress = () => {
        // console.log(props);
        const lresult = window.confirm("Are you sure you want to logout");
        if (lresult) {
            localStorage.clear();
            // stopSetInterval();
            amplitude.getInstance().logEvent("Logout_Completed");
            handleDoctorInout("out");
            history.push('/login');
        }
    };

    // const stopSetInterval = () => {
    //     clearTimeout(setIntervalId);
    // };

    const handleDoctorInout = (inoutnow) => {
        if(dIN) {
            amplitude
                .getInstance()
                .logEvent("Doctor_status_change", { status: "out" });
            showToast(
                "Marking as OUT. All future appointments will be informed that doctor is on emergency/break",
                CONSTANTS.TOAST_SEVERITY.ERROR
            );
        } else {
            amplitude
                .getInstance()
                .logEvent("Doctor_status_change", { status: "in" });
            showToast(
                "Marking as IN. All future Appointments will be informed accordingly",
                CONSTANTS.TOAST_SEVERITY.SUCCESS
            );
        }

        let inout = dIN ? "out" : "in";

        if (inoutnow === "in") inout = "in";
        if (inoutnow === "out") inout = "out";

        const url =
            "https://cxuserdb.cloudninecare.email/videoconsultation/doctorinout/" +
            localStorage.getItem("d_id") +
            "/" +
            inout;
        fetch(url)
            .then((response) => response.json())
            .then((res) => {
                // console.log(res);
                if (res.status === 1) setDIN(!dIN );
            })
            .catch((error) => {});
    };

    const showToast = (message, alertType) => {
        setToastMessage(message);
        setToastSeverity(alertType);
        setShouldShowToast(true);
    };

    const hideToast = (event, reason) => {
        if (reason === "clickaway") return;
        setToastMessage("");
        setToastSeverity("");
        setShouldShowToast(false);
    };


    const handleOnCallSupport = () => {
        const suppportNumber = ["9900025892", "7338454536"];
        const randomNumber = Math.round(Math.random());
        handleOnCallPress(suppportNumber[randomNumber]);
    };

    const handleOnCallPress = (patientMobile) => {
        const docMobile = localStorage.getItem("d_mobile");
        const isConfirmed = window.confirm(
            `Are you sure to connect phone call to ${patientMobile}. You shall recieve call first and it will connect with the other person`
        );
        if (isConfirmed) {
            amplitude
                .getInstance()
                .logEvent("Call_Initiated", { to: patientMobile });
            initiatePhoneCall(docMobile, patientMobile).then(
                (response) => {
                    // console.log(response);
                    if (response && response.status)
                        showWindowAlert(
                            `Call intiated to ${patientMobile} successfully. `
                        );
                    else
                        showWindowAlert(
                            `Sorry! Connection with ${patientMobile} failed.`
                        );
                }
            );
        }
    };

    const initiatePhoneCall = (from, to) => {
        const url = `https://cxuserdb.cloudninecare.email/videoconsultation/callconnectv2/${from}/${to}`;

        return fetch(url)
            .then((response) => response.json())
            .catch((error) => {});
    };

    const getGreetingTime = (m) => {
        var g = null; //return g

        if(!m || !m.isValid()) { return; } //if we can't find a valid or filled moment, we return.

        var split_afternoon = 12 //24hr time to split the afternoon
        var split_evening = 17 //24hr time to split the evening
        var currentHour = parseFloat(m.format("HH"));

        if(currentHour >= split_afternoon && currentHour <= split_evening) {
            g = "Afternoon!";
        } else if(currentHour >= split_evening) {
            g = "Evening!";
        } else {
            g = "Morning!";
        }

        return g;
    }

        return  (
        <header className="header">
            <div className="header__toggle">
                <h3 style={{fontSize: '1.25vw'}}><p style={{color: '#919191'}}>{"Good " + getGreetingTime(moment()) }</p>
                    {localStorage.getItem("d_name")}
                </h3>
                <div>
                    <div>
                        <Switch
                            checked={dIN}
                            handleColor="white"
                            name={'Available'}
                            offColor="white"
                            onChange={handleDoctorInout}
                            onColor='#21D393'
                        />
                        <p>Available</p>
                    </div>
                    <div
                        style={{cursor: 'pointer'}}
                        onClick={handleOnCallSupport}
                    >
                        <img src={danger} alt="Support" />
                        <p>Support</p>
                    </div>
                    <div
                        style={{cursor: 'pointer'}}
                        onClick={handleOnLogoutPress}
                    >
                        <img src={logout} alt="logout" />
                        <p>Logout</p>
                    </div>
                </div>
            </div>
            <Toast
                visible={shouldShowToast}
                severity={toastSeverity}
                message={toastMessage}
                handleClose={hideToast}
            />
        </header>
        )
}


export default Header;
