import Note1 from "../../images/abbreviation1.png";
import Note2 from "../../images/abbreviation2.png";
import first1 from "../../images/upcoming1.png";
import first2 from "../../images/upcoming2.png";
import Video1 from "../../images/video1.png";
import Video2 from "../../images/video2.png";
import Folder1 from "../../images/history1.png";
import Folder2 from "../../images/history2.png";
import Template1 from "../../images/template1.png";
import Template2 from "../../images/template2.png";

export const sideNavData = [
  {
    ListItem: "Today's Appointments",
    ListImage: first1,
    ListImage2: first2,
    route: "/today-appointments",
  },
  {
    ListItem: "History Past 7 Days",
    ListImage: Folder1,
    ListImage2: Folder2,
    route: "/history-7-days",
  },
  {
    ListItem: "Future Appointments",
    ListImage: first1,
    ListImage2: first2,
    route: "/future-appointments",
  },
  {
    ListItem: "My Templates",
    ListImage: Template1,
    ListImage2: Template2,
    route: "/my-templates",
  },
  {
    ListItem: "My Note/Abbreviation",
    ListImage: Note1,
    ListImage2: Note2,
    route: "/my-note",
  },
  {
    ListItem: "Quick Consultation",
    ListImage: Video1,
    ListImage2: Video2,
    route: "/quick-consultation",
  },
  {
    ListItem: "IP Consultation",
    ListImage: Video1,
    ListImage2: Video2,
    route: "/ip-consultation",
  },
];
