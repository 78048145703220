import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@mui/material";
import Paper from "@mui/material/Paper";
import { withStyles } from "@mui/styles";
import amplitude from "amplitude-js";
import React, { Component } from "react";
import {
  FetchMpidWithPhoneNumber,
  getAllDoctorsForIp,
  getHospitalIdAndClinicForIp,
  SubmitIpApi,
} from "../Api/Api";
import { Toast } from "../components/GenericComponents";
import { CONSTANTS } from "../Constants";
import { bugSnagNotify } from "../Utils/utility";

const styles = (theme) => ({
  root: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#99219b",
    },

    "& .MuiInputLabel-outlined.Mui-focused": {
      color: "#99219b",
    },
  },
});

class IpConsultation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      doctorId: "",
      doctorName: "",
      doctorMobile: "",
      abbreviation: "",
      loading: true,
      enableEdit: false,
      shouldShowToast: false,
      toastMessage: "",
      toastSeverity: "",
      quickurl: null,
      toggle: false,
      copied: false,
      sendModalOpen: false,
      CustomerMobile: null,
      smsMessageText: "The doctor is waiting for you to join the consultation.",

      IpModal: false,
      Ipmobile: "",
      ipmpid: "",
      IpHospitalId: null,
      hospitalIdList: [],
      ipclinic_name: "",
      ippatientname: "",
      mode: "Ipvideo",
      ipmpidList: [],
      allDoctorsForIp: [],
      ipdoctorId: "",
      ipdoctorName: "",
      disableIpBtn: false,
    };
  }

  componentDidMount() {
    this.setState({
      doctorId: localStorage.getItem("d_id"),
      doctorName: localStorage.getItem("d_name"),
      doctorMobile: localStorage.getItem("d_mobile"),
    });

    getHospitalIdAndClinicForIp(localStorage.getItem("d_id"))
      .then((res) => {
        // console.log(res.data);
        this.setState({ hospitalIdList: res.data[0].getHospitals });
      })
      .catch((error) => {
        this.showToast(
          "Failed to fetch Hospitals",
          CONSTANTS.TOAST_SEVERITY.ERROR
        );
        bugSnagNotify(
          "Get hospital ID and clinic for IP VC",
          localStorage.getItem("d_id"),
          error
        );
      });
    amplitude
      .getInstance()
      .init("829c5b10e843e0edefdc4d33f04ff290", localStorage.getItem("d_name"));
    this.fetchDoctorProfile();
  }

  isAuthorized = () => {
    const doctorId = localStorage.getItem("d_id");
    const doctorName = localStorage.getItem("d_name");
    const doctorMobile = localStorage.getItem("d_mobile");
    return doctorId && doctorName && doctorMobile ? true : false;
  };

  fetchDoctorProfile() {
    const url =
      "https://cx-server2.cloudninecare.com/qkams/get/doctor/profile/?docId=" +
      localStorage.getItem("d_id");

    fetch(url)
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.status === 1) {
          this.setState({
            dLifetrenzId: resp.data[0].lifetrenzId,
            dSiteCode: resp.data[0].getHospitals[0].siteCode,
            dClinicname: resp.data[0].getHospitals[0].name,
          });
        }
      })
      .catch((error) => {
        bugSnagNotify(
          "Fetch doctor profile failed",
          localStorage.getItem("d_id"),
          error
        );
      });
  }

  showToast = (message, alertType) => {
    this.setState({
      shouldShowToast: true,
      toastSeverity: alertType,
      toastMessage: message,
    });
  };

  handleIpConsultBook = () => {
    this.setState({ IpModal: true });
  };

  IpMobilehandleChange = (e) => {
    if (e.target.value.length === 10) {
      this.setState({ Ipmobile: e.target.value });
      FetchMpidWithPhoneNumber(e.target.value)
        .then((res) => {
          if (res.status === 0) {
            this.showToast(
              "Phone Number do not Exist",
              CONSTANTS.TOAST_SEVERITY.ERROR
            );
            this.setState({
              ipmpid: "",
              ippatientname: "",
            });
            return;
          }
          console.log(res.data);
          this.setState({
            ipmpidList: res.data,
          });
        })
        .catch((err) => {
          this.showToast(
            "Something went wroong.",
            CONSTANTS.TOAST_SEVERITY.ERROR
          );
          return;
        });
    } else {
      this.setState({
        Ipmobile: e.target.value,
        ipmpidList: [],
        ippatientname: "",
      });
    }
  };

  handleHospitalIdChange = (event, value) => {
    getAllDoctorsForIp(event.target.value.siteCode)
      .then((res) => {
        // console.log(res.data);
        this.setState({ allDoctorsForIp: res.data });
      })
      .catch((err) => {
        bugSnagNotify(
          "Failed to fetch all doctors",
          event.target.value.siteCode,
          err
        );
        this.showToast(
          "Failed to fetch all doctors",
          CONSTANTS.TOAST_SEVERITY.ERROR
        );
      });

    this.setState({
      IpHospitalId: event.target.value.siteCode,
      ipclinic_name: event.target.value.name,
    });
  };

  handleMpidIdChange = (event, value) => {
    this.setState({
      ipmpid: event.target.value.mpid,
      ippatientname: event.target.value.first_name,
    });
  };

  handleCloseIpModal = () => {
    this.setState({
      IpModal: false,
      Ipmobile: "",
      ipmpid: "",
      Ipsitecode: "",
      IpHospitalId: null,
      ipclinic_name: "",
      ippatientname: "",
      ipmpidList: [],
    });
  };

  handleDoctorNameChange = (event, value) => {
    this.setState({
      ipdoctorId: event.target.value.id,
      ipdoctorName: event.target.value.name,
    });
  };

  submitIp = () => {
    this.setState({ disableIpBtn: true });

    var body = {
      senderdoctorid: this.state.doctorId,
      mobile: this.state.Ipmobile,
      mpid: this.state.ipmpid,
      sitecode: this.state.IpHospitalId,
      clinic_name: this.state.ipclinic_name,
      doctor_name: this.state.ipdoctorName,
      doctorid: this.state.ipdoctorId,
      patient_name: this.state.ippatientname,
      mode: "ipvideo",
    };

    SubmitIpApi(body)
      .then((res) => {
        // console.log(res, res.data);
        if (res.status === 1) {
          amplitude
            .getInstance()
            .logEvent("IP video consultation appointment Created.", {
              senderdoctorid: this.state.doctorId,
              mobile: this.state.Ipmobile,
              mpid: this.state.ipmpid,
              sitecode: this.state.IpHospitalId,
              clinic_name: this.state.ipclinic_name,
              doctor_name: this.state.ipdoctorName,
              doctorid: this.state.ipdoctorId,
              patient_name: this.state.ippatientname,
            });

          this.showToast(
            "Your IP video consultation appointment is Created.",
            CONSTANTS.TOAST_SEVERITY.SUCCESS
          );
          this.setState({
            IpModal: false,
            Ipmobile: "",
            ipmpid: "",
            Ipsitecode: "",
            IpHospitalId: null,
            ipclinic_name: "",
            ippatientname: "",
            ipmpidList: [],
          });
        } else {
          this.showToast(
            "Please fill all the fields properly",
            CONSTANTS.TOAST_SEVERITY.INFO
          );
        }
        this.setState({ disableIpBtn: false });
      })
      .catch((err) => {
        this.showToast("Something went Wrong", CONSTANTS.TOAST_SEVERITY.ERROR);
        this.setState({ disableIpBtn: false });
      });
  };
  render() {
    const IpConsultModal = (
      <Dialog
        open={this.state.IpModal}
        onClose={() => {
          this.handleCloseIpModal();
        }}
      >
        <DialogTitle id="simple-dialog-title" disableTypography>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h3
              style={{
                fontWeight: "bold",
              }}
            >
              IP Consulation
            </h3>

            <IconButton onClick={() => this.handleCloseIpModal()}>
              <CloseIcon color={"grey"} />
            </IconButton>
          </div>
        </DialogTitle>

        <div style={{ margin: "0 1.5rem", padding: "0 1rem" }}>
          <div>
            <TextField
              style={{ width: "23rem", margin: "0.4rem 0" }}
              value={this.state.Ipmobile}
              label={"Enter Mobile"}
              onChange={(e) => {
                this.IpMobilehandleChange(e);
              }}
              margin={"dense"}
              variant="outlined"
              id="mui-theme-provider-outlined-input"
              required
              type="number"
            />
          </div>

          <div>
            <TextField
              margin={"dense"}
              style={{ width: "23rem", margin: "0.4rem 0" }}
              variant="outlined"
              label={"Mpid"}
              onChange={(event, value) => this.handleMpidIdChange(event, value)}
              select
            >
              {this.state.ipmpidList.map((list, index) => (
                <MenuItem key={index} value={list}>
                  {`${list.mpid} (${list.first_name})`}
                </MenuItem>
              ))}
            </TextField>
          </div>

          <div>
            <TextField
              margin={"dense"}
              style={{ width: "23rem", margin: "0.4rem 0" }}
              label={"Hospital Name"}
              variant="outlined"
              onChange={(event, value) =>
                this.handleHospitalIdChange(event, value)
              }
              select
            >
              {this.state.hospitalIdList.map((list, index) => (
                <MenuItem key={index} value={list}>
                  {list.name}
                </MenuItem>
              ))}
            </TextField>
          </div>

          <div>
            <TextField
              margin={"dense"}
              style={{ width: "23rem", margin: "0.4rem 0" }}
              label={"Doctor Name"}
              variant="outlined"
              onChange={(event, value) =>
                this.handleDoctorNameChange(event, value)
              }
              select
            >
              {this.state.allDoctorsForIp.map((list, index) => (
                <MenuItem key={index} value={list}>
                  {list.name}
                </MenuItem>
              ))}
            </TextField>
          </div>

          <div>
            <TextField
              style={{ width: "23rem", margin: "0.4rem 0" }}
              value={this.state.ippatientname}
              label={"Patient Name"}
              // onChange={this.IpMobilehandleChange()}
              margin={"dense"}
              variant="outlined"
              id="mui-theme-provider-outlined-input"
              required
            />
          </div>
        </div>
        <br />
        <div>
          <Button
            disabled={this.state.disableIpBtn}
            variant="contained"
            style={{
              margin: "0.5rem 0",
              marginLeft: "17rem",
              cursor: "pointer",
              border: "0.1rem solid #851e87",
              minWidth: "10vw",
              height: "2.1rem",
              borderRadius: "0.7rem",
              color: "white",
              backgroundColor: "#851e87",
              fontSize: "0.9rem",
              fontWeight: "1rem",
            }}
            onClick={() => this.submitIp()}
          >
            Submit
          </Button>
        </div>
      </Dialog>
    );

    if (!this.isAuthorized()) {
      return <p>Your are not authorized</p>;
    } else {
      return (
        <div style={{ padding: "1.2rem 1.7rem" }}>
          <Paper>
            {
              <div style={{ textAlign: "center", padding: "9rem 0" }}>
                <h3 style={{ padding: "1rem 0" }}>
                  Are you sure you want to create an IP Consultation?
                </h3>
                <button
                  style={{
                    marginLeft: "1vw",
                    cursor: "pointer",
                    border: "0.1rem solid #851e87",
                    minWidth: "17vw",
                    height: "2.1rem",
                    borderRadius: "0.7rem",
                    color: "white",
                    backgroundColor: "#851e87",
                    fontSize: "0.9rem",
                    fontWeight: "1rem",
                  }}
                  onClick={this.handleIpConsultBook}
                >
                  Ok
                </button>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
              </div>
            }
            {IpConsultModal}
            <Toast
              visible={this.state.shouldShowToast}
              severity={this.state.toastSeverity}
              message={this.state.toastMessage}
              handleClose={this.hideToast}
            />
          </Paper>
        </div>
      );
    }
  }
}

export default withStyles(styles)(IpConsultation);
